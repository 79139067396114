import axios from 'axios';
const { isCancel } = axios;

export class CancelRequestErrorHandler {
  canHandle(error: Error) {
    return isCancel(error);
  }

  async handle() {
    // errors suppression for abortable request
    return;
  }
}
