import numeral from 'numeral';
import Vue from 'vue';

numeral.options.scalePercentBy100 = false;

const Numeral = (function Numeral() {
  const options: any = {
    delimiters: {
      thousands: ' ',
      decimal: ',',
    },
    abbreviations: {
      thousand: 'k',
      million: 'm',
      billion: 'b',
      trillion: 't',
    },
    currency: {
      symbol: '$',
    },
  };
  numeral.register('locale', 'ru', options);
  numeral.locale('ru');
  Vue.prototype.$numeral = numeral;
})();

export default Numeral;
