export default {
  en: {
    popups: {
      welcome: {
        header: 'Welcome!',
        texts: [
          {
            text: 'Here you can find how to create ADs and monetize your traffic.',
            info: null,
          },
          {
            text: 'AD Code allows you to quickly update or change Ad Spots settings.',
            info: null,
          },
          {
            text:
              'When you have installed an AD Code on your website, AD spot formats will be available in the panel where you can manage them.',
            info: null,
          },
          {
            text: 'Note: you can create several AD spots in one AD Code.',
            info: null,
          },
          {
            text:
              'Also, you can create one AD code for ALL your sites or create a separate AD code for each site.',
            info: null,
          },
          {
            text: 'If you have any questions, contact your manager or our support team.',
            info: null,
          },
        ],
      },
      inpage_push_info: {
        texts: [
          'Hi, inpage is a highly customisable ad format, however, not all settings are available to publishers. If you are unhappy with such mechanics as:',
          'Contact your personal manager and we will adjust it just the way you want.',
        ],
        mechanics: [
          'Position choice;',
          'Cross button;',
          'Skins and ad verticals;',
          'Or you are concerned about search engines (Google and Yandex) friendliness.',
        ],
      },
    },
    category_group: {
      a: 'These are users who are interested in adult content or products (labeled 18+)',
      m:
        'Includes “white” offers without adult materials, mainly sites for dating, both long-term and short-term',
    },
    spots: {
      banner: {
        bright_banner: {
          info: 'Darkening the area around the banner for 2 seconds when the banner is in view',
        },
        sticky_banner: {
          info:
            'Sticking to the top and bottom borders of the screen to the height of the banner when scrolling',
        },
      },
    },
    top_fixed: {
      paxum_issues_clickadilla:
        'Technical work on PAXUM. Payouts in PAXUM are temporarily unavailable.',
      paxum_issues_mybid:
        'PAXUM under maintenance. PAXUM payment requests are temporarily stopped.',
      confirm_email: {
        text: 'Your email is not confirmed, please confirm',
        link_text: 'here',
      },
      payments_terms: {
        text: 'We have improved payments, now they will be made from 4 to 7 days',
      },
    },
    statistics: {
      no_data_error: {
        text_before_link: `You don't have any active spots yet. Please`,
        link_text: 'create AD Code',
        text_after_link: 'and run spots to see statistics.',
      },
    },
  },

  ru: {
    popups: {
      welcome: {
        header: 'Добро пожаловать!',
        texts: [
          {
            text: 'На этой странице вы можете создавать рекламные коды.',
            info: null,
          },
          {
            text:
              'Они позволяют легко и удобно управлять всей рекламой на сайте (или даже на нескольких сразу) из одного окна.',
            info: null,
          },
          {
            text:
              'Прежде всего нужно создать единый тег (AD Code). Внутри него можно создать сколько угодно спотов любых форматов.',
            info: null,
          },
          {
            text:
              'После вставки его JS-скрипта AD Code в <head> сайта, почти все форматы, созданные в нём, начнут работать.',
            info:
              'Для подключения веб-пушей понадобится отдельно скачать сервис-воркер, подробности у вашего менеджера',
          },
          {
            text:
              'Вы можете использовать один AD Code для нескольких сайтов, но мы не рекомендуем так делать.',
            info:
              'Использование по одному AD Code на домен даёт более чистую статистику и позволяет видеть, как рекламодатели оценивают ваши домены',
          },
          {
            text: 'Свяжитесь с вашим менеджером для получения подробной информации.',
            info: null,
          },
        ],
      },
      inpage_push_info: {
        texts: [
          'Привет, инпейдж очень гибкий формат c кучей настроек, но не все из них доступны паблишерам. Если тебя не устраивают такие механики как:',
          'Свяжись c личным менеджером и мы настроим все именно так, как бы ты хотел.',
        ],
        mechanics: [
          'Расположение на сайте;',
          'Работа кнопки закрытия;',
          'Скины и вертикали рекламы;',
          'Или тебя беспокоит, как поисковыое движки (Гугл и Яндекс) относятся к такой рекламе.',
        ],
      },
    },
    category_group: {
      a: 'Это пользователи, которым интересны контент или товары для взрослых (с маркировкой 18+)',
      m:
        'Включает в себя “белые” офферы без адалтных материалов, в основном сайты для знакомств, как долгосрочных, так и краткосрочных',
    },
    spots: {
      banner: {
        bright_banner: {
          info:
            'Затемнение на 2 секунды области вокруг баннера, когда баннер находится в зоне видимости',
        },
        sticky_banner: {
          info: 'Подлипание к верхней и нижней границе экрана на высоту баннера при скролле',
        },
      },
    },
    top_fixed: {
      paxum_issues_clickadilla:
        'Technical work on PAXUM. Payouts in PAXUM are temporarily unavailable.',
      paxum_issues_mybid:
        'PAXUM under maintenance. PAXUM payment requests are temporarily stopped.',
      confirm_email: {
        text: 'Ваша почта не подтверждена, пожалуйста, подтвердите ее',
        link_text: 'по ссылке',
      },
      payments_terms: {
        text: 'Мы усовершенствовали выплаты, теперь они будут производиться от 4 до 7 дней',
      },
    },
    statistics: {
      no_data_error: {
        text_before_link: 'У вас еще нет активных спотов. Пожалуйста,',
        link_text: 'создайте AD Code',
        text_after_link: 'и запустите споты, чтобы посмотреть статистику.',
      },
    },
  },
};
