const rules = {
  required: (value: any) => !!value || 'Required',

  email: (value: string) => {
    const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return pattern.test(value) || 'Invalid e-mail.';
  },

  url: (value: string) => {
    const pattern = /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w.-]+)+[\w\-._~:/?#[\]@!$&'()*+,;=.{}]+$/gim;
    return pattern.test(value) || 'This URL is invalid';
  },

  phone: (value: string) => /^\+?[\d. -]+$/.test(value) || 'Invalid phone number',

  telegram: (value: string) => /^[a-zA-Z0-9_@]{5,}$/.test(value) || 'Invalid telegram username',

  minString: (val: string, max: number | string) => (val || '').length >= +max || `Min ${max} max`,
  maxString: (val: string = '', max: number | string) =>
    val.length > +max ? `Max ${max} characters` : true,

  // number values validation
  number: (val: number | string) =>
    (typeof +val === 'number' && +val > 0) || 'Value must be a positive number',
  notANumber: (val: number | string) => isNaN(+val) || 'Value must not be a number',
  minNumber: (val: number | string, min: number | string) =>
    val >= min || `Value must be more than or equal ${min}`,
  maxNumber: (val: number | string, max: number | string) =>
    val <= max || `Value must be less than or equal ${max}`,
  minMaxNumber: (val: number | string, min: number | string, max: number | string) =>
    (val >= min && val <= max) || `Value must be between ${min} and ${max}`,

  match: (value: string, matchValue: string) =>
    String(value) === String(matchValue) || 'Values do not match.',

  css: (value: string) => {
    const pattern = /^(.*\{*\})+$/gim;
    return pattern.test(value) || 'This CSS is invalid';
  },

  liveID: (value: string) => {
    const pattern = /^live:(\.cid\.)?.+$/gim;
    return pattern.test(value) || 'Live ID is invalid';
  },

  skype: (value: string) =>
    [rules.email(value), rules.liveID(value)].includes(true) || 'Invalid skype username or Live ID',
};

export default rules;
