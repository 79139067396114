






























import { detectAnyAdblocker } from 'vue-adblock-detector';
import { Component, Vue } from 'vue-property-decorator';

import Button from '@/components/v2/Button.vue';
import Card from '@/components/v2/Card.vue';
import Modal from '@/components/v2/Modal.vue';
@Component({
  name: 'AdblockDetectPopup',
  components: { Button, Modal, Card },
})
export default class AdblockDetectPopup extends Vue {
  dialog = false;
  async mounted() {
    detectAnyAdblocker().then((detected: boolean) => {
      if (detected) {
        this.openPopup();
      }
    });
  }

  openPopup() {
    this.dialog = true;
  }

  closePopup() {
    this.dialog = false;
  }
}
