import isEqual from 'lodash/isEqual';
import { NavigationGuardNext, Route } from 'vue-router';

import store from '@/store';
import { hasPrivilegedRole } from '@/store/helpers';
import { REFRESH } from '@/store/types/actions';
import { initialize } from '@/utils/init';

export const authMiddleware = async ({ to, next }: { to: Route; next: NavigationGuardNext }) => {
  if (!store.getters.isAuthenticated) {
    try {
      await store.dispatch(REFRESH);
      await initialize(false, null);
      to.meta?.guest ? next('/') : next();
    } catch (e) {
      to.meta?.guest ? next() : next('/login');
    }
  } else {
    next();
  }
};

export const permissionMiddleware = async ({
  to,
  next,
}: {
  to: Route;
  next: NavigationGuardNext;
}) => {
  store.getters.isAuthenticated && store.getters.permissions[to.name!]?.includes('viewAny')
    ? next()
    : next(false);
};

export const privilegedMiddleware = async ({ next }: { next: NavigationGuardNext }) => {
  hasPrivilegedRole() ? next() : next(false);
};

export const publisherOnMobileMiddleware = async ({
  from,
  next,
}: {
  from: Route;
  next: NavigationGuardNext;
}) => {
  if (isEqual(store.getters.roles, ['publisher']) && window.innerWidth < 600 && from.path === '/') {
    next('/ad-codes');
  } else {
    next();
  }
};
