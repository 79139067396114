



































import { Component, Vue } from 'vue-property-decorator';

import QuestionIcon from '@/components/svg/Question.vue';
import Button from '@/components/v2/Button.vue';
import Card from '@/components/v2/Card.vue';
import Modal from '@/components/v2/Modal.vue';
import Tooltip from '@/components/v2/Tooltip.vue';
import content from '@/i18n/translations';

@Component({
  name: 'WelcomePopup',
  components: { Button, Modal, QuestionIcon, Card, Tooltip },
})
export default class WelcomePopup extends Vue {
  dialog = false;
  locale: Locale = window.navigator.language.split('-')[0] === 'ru' ? 'ru' : 'en';
  content = content[this.locale].popups.welcome;

  get env() {
    return process.env;
  }

  mounted() {
    this.$popups.welcome.$on('show', () => {
      this.show();
    });
    this.$popups.welcome.$on('close', () => (this.dialog = false));
  }

  show() {
    this.dialog = true;
  }

  links = [
    {
      name: 'Telegram',
      href: `https://t.me/${this.env.VUE_APP_ORG_TELEGRAM.replace('@', '')}`,
    },
    {
      name: 'E-mail',
      href: `mailto:${this.env.VUE_APP_ORG_EMAIL}`,
    },
    {
      name: 'Skype',
      href: `skype:${this.env.VUE_APP_ORG_SKYPE}?chat`,
    },
  ];

  closePopup() {
    this.$popups.welcome.$emit('close');
  }
}
