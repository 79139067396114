import Vue from 'vue';

import { Breakpoint } from '@/interfaces';

class Resize {
  _screens: Record<Breakpoint, number>;
  _breakpoints: Breakpoint[];

  constructor(screens: Record<Breakpoint, string>) {
    this._screens = Object.entries(screens).reduce(
      (acc, [screen, value]) => ({ ...acc, [screen]: value.replace('px', '') }),
      {} as Record<Breakpoint, number>
    );
    this._breakpoints = Object.keys(screens) as Breakpoint[];
  }

  resize() {
    this._breakpoints.forEach(
      breakpoint =>
        (Vue.prototype.$breakpoints[breakpoint] = this.getBreakpoints(this._screens)[breakpoint])
    );
  }

  listen() {
    window.addEventListener('resize', () => this.resize(), { passive: true });
  }

  observe() {
    Vue.prototype.$breakpoints = Vue.observable(
      this._breakpoints.reduce(
        (acc, breakpoint) => ({
          ...acc,
          [breakpoint]: this.getBreakpoints(this._screens)[breakpoint],
        }),
        {}
      )
    );
  }

  getBreakpoints(screens: Record<Breakpoint, number>): Record<Breakpoint, boolean> {
    const {
      screen: { width },
    } = window;
    return {
      sm: width < screens.sm,
      md: width >= screens.sm && width < screens.md,
      lg: width >= screens.md && width < screens.lg,
      xl: width >= screens.lg && width < screens.xl,
      xxl: width >= screens.xxl,
      '2xl': width >= screens.xl,
    };
  }
}

export default Resize;
