import 'vuetify/dist/vuetify.min.css';
import '@mdi/font/css/materialdesignicons.css';

import Vue from 'vue';
import Vuetify from 'vuetify/lib';

import { ThemeService } from '@/services/StorageService';

const vuetify = new Vuetify({
  icons: {
    iconfont: 'mdi',
  },
  theme: {
    dark: ThemeService.getDarkTheme() ?? false,
    themes: {
      dark: {
        primary: '#82CDFF',
        secondary: '#A7A7A7',
        tertiary: '#FFF',
        quaternary: '#FFCF44',
        quinary: '#B15DFF',
        senary: '#72DEFF',
        success: '#82CDFF',
        google: '#4285F4',
        yandex: '#FF0000',
      },
      light: {
        google: '#FFFFFF',
        yandex: '#FFCC00',
      },
    },
  },
});

Vue.use(Vuetify);

export default vuetify;
