import store from '@/store';
import { SET_ERROR } from '@/store/types/mutations';

export class ServerErrorHandler {
  canHandle(error: any) {
    return error.response && error.response.status === 500;
  }

  async handle() {
    if (store.getters.environment === 'production') {
      await store.commit(SET_ERROR, 'server_error');
    }
  }
}
