import isNil from 'lodash/isNil';

import {
  Action,
  IPaymentDetail,
  IRole,
  IUser,
  IUserExtended,
  PaymentMethod,
  PaymentMethodDetail,
  Resource,
} from '@/interfaces';
import store from '@/store';

const can = (
  action: Action,
  resource: Resource,
  user: IUserExtended | IUser = store.getters.user.data
): boolean => {
  const permissions = {
    resource_permissions: user.permissions?.resource_permissions || [],
    ...('nested' in (user.permissions ?? {}) ? (user.permissions as any).nested : user.permissions),
  };
  return permissions[resource]?.includes(action);
};

const hasPrivilegedRole = () => {
  return store.getters.rolesList.data.some(
    ({ name, privileged }: IRole) => store.getters.roles?.includes(name) && privileged
  );
};

const getPaymentMethodDetails = (
  paymentMethod: PaymentMethod | null,
  paymentMethodDetails: Partial<Record<PaymentMethodDetail, string>> | null
) => {
  if (isNil(paymentMethod) || isNil(paymentMethodDetails)) return '';
  const fields = (store.getters.paymentDetails as IPaymentDetail[])
    .filter(({ isWallet }) => isWallet)
    .reduce(
      (acc, { detail, method }) => ({
        ...acc,
        [method]: detail,
      }),
      {} as Record<PaymentMethod, PaymentMethodDetail>
    );
  return paymentMethodDetails[fields[paymentMethod]];
};

export { can, getPaymentMethodDetails, hasPrivilegedRole };
