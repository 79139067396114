












































































import { Component, Prop, Vue } from 'vue-property-decorator';
import { Getter } from 'vuex-class';

import Button from '@/components/v2/Button.vue';
import EmailIcon from '@/components/v2/Icons/EmailIcon.vue';
import SkypeIcon from '@/components/v2/Icons/SkypeIcon.vue';
import TelegramIcon from '@/components/v2/Icons/TelegramIcon.vue';
import { Contact, IUserExtended, Manager } from '@/interfaces';

@Component({
  name: 'Info',
  components: {
    EmailIcon,
    SkypeIcon,
    TelegramIcon,
    Button,
  },
})
export default class Info extends Vue {
  @Prop({ default: null }) userManager!: Manager | null;
  @Prop({ default: false, type: Boolean }) isAlternative!: boolean;

  @Getter fullUserName!: IUserExtended['first_name'] & IUserExtended['last_name'];

  icons = {
    email: EmailIcon,
    skype: SkypeIcon,
    telegram: TelegramIcon,
  };
  defaultManager = {
    first_name: '',
    last_name: '',
    nickname: '',
    email: process.env.VUE_APP_ORG_EMAIL,
    contacts: [
      { name: 'telegram', value: process.env.VUE_APP_ORG_TELEGRAM },
      { name: 'skype', value: process.env.VUE_APP_ORG_SKYPE },
    ],
  };

  get manager() {
    return this.userManager ?? this.defaultManager;
  }

  generateContact(contact: Contact) {
    const dictionary: Dictionary<{
      href: string;
      text: Contact['value'];
    }> = {
      email: {
        href: `mailto:${contact.value}?subject=${process.env.VUE_APP_ORG_NAME} (${this.fullUserName})`,
        text: contact.value,
      },
      skype: {
        href: `skype:${contact.value}?chat`,
        text: process.env.VUE_APP_ORG_NAME === 'Onclicka' ? 'Onclicka Support' : contact.value,
      },
      telegram: {
        href: `https://t.me/${contact.value.replace('@', '')}`,
        text: contact.value.replace('@', ''),
      },
    };
    return dictionary[contact.name];
  }
}
