import * as Sentry from '@sentry/browser';
import { ActionTree, GetterTree, MutationTree } from 'vuex';

import { AuthState, ILoginPayload, ISignUpPayload, RootState } from '@/interfaces';
import AuthService from '@/services/AuthService';
import { LOGIN, LOGOUT, REFRESH, REGISTER, REGISTER_BY_INVITATION } from '@/store/types/actions';
import { SET_ACCESS_TOKEN, SET_IS_AUTHENTICATED } from '@/store/types/mutations';
import { generateVisitorId } from '@/utils/helpers';
import { parseJWT } from '@/utils/parser';

const state: AuthState = {
  isAuthenticated: false,
  accessToken: {
    loading: false,
    error: null,
    data: {
      token: '',
      expiresAt: 0,
    },
  },
};

const getters: GetterTree<AuthState, RootState> = {
  isAuthenticated: state => state.isAuthenticated,
  accessToken: state => state.accessToken,
};

const mutations: MutationTree<AuthState> = {
  [SET_IS_AUTHENTICATED](state, isAuthenticated: boolean) {
    state.isAuthenticated = isAuthenticated;
  },
  [SET_ACCESS_TOKEN](state, payload: AuthState['accessToken']) {
    state.accessToken = payload;
  },
};

const actions: ActionTree<AuthState, RootState> = {
  async [REGISTER]({ state, commit }, payload: ISignUpPayload) {
    try {
      commit(SET_ACCESS_TOKEN, { ...state.accessToken, loading: true, error: null });
      const fingerprint_hash = await generateVisitorId();
      const recaptchaKey = process.env.VUE_APP_RECAPTCHA_SITE_KEY;
      let recaptcha_token = null;
      if (recaptchaKey !== '_') {
        recaptcha_token = await window.grecaptcha.execute(recaptchaKey, { action: 'submit' });
      }
      await AuthService.register({
        ...payload,
        fingerprint_hash,
        recaptcha_token,
      });
    } catch (error) {
      commit(SET_ACCESS_TOKEN, { ...state.accessToken, loading: false, error });
      throw error;
    }
  },

  async [REGISTER_BY_INVITATION]({ commit }, payload: ISignUpPayload) {
    try {
      commit(SET_ACCESS_TOKEN, { ...state.accessToken, loading: true, error: null });
      const fingerprint_hash = await generateVisitorId();
      const recaptchaKey = process.env.VUE_APP_RECAPTCHA_SITE_KEY;
      let recaptcha_token = null;
      if (recaptchaKey !== '_') {
        recaptcha_token = await window.grecaptcha.execute(recaptchaKey, { action: 'submit' });
      }
      await AuthService.registerByInvitation({
        ...payload,
        fingerprint_hash,
        recaptcha_token,
      });
    } catch (error) {
      commit(SET_ACCESS_TOKEN, { ...state.accessToken, loading: false, error });
      throw error;
    }
  },

  async [LOGIN]({ commit }, payload: ILoginPayload) {
    try {
      commit(SET_ACCESS_TOKEN, { ...state.accessToken, loading: true, error: null });
      const fingerprint_hash = await generateVisitorId();
      const recaptchaKey = process.env.VUE_APP_RECAPTCHA_SITE_KEY;
      let recaptcha_token = null;
      if (recaptchaKey !== '_') {
        recaptcha_token = await window.grecaptcha.execute(recaptchaKey, { action: 'submit' });
      }
      const response = await AuthService.login({ ...payload, fingerprint_hash, recaptcha_token });
      commit(SET_ACCESS_TOKEN, {
        ...state.accessToken,
        data: {
          token: response.access_token,
          expiresAt: parseJWT(response.access_token).exp * 1000,
        },
        loading: false,
      });
      commit(SET_IS_AUTHENTICATED, true);
    } catch (error) {
      commit(SET_ACCESS_TOKEN, { ...state.accessToken, loading: false, error });
      throw error;
    } finally {
      Sentry.setUser({ email: payload.email });
    }
  },

  async [REFRESH]({ commit }) {
    try {
      commit(SET_ACCESS_TOKEN, { ...state.accessToken, loading: true, error: null });
      const response = await AuthService.refresh();
      commit(SET_ACCESS_TOKEN, {
        ...state.accessToken,
        data: {
          token: response.access_token,
          expiresAt: parseJWT(response.access_token).exp * 1000,
        },
        loading: false,
      });
      commit(SET_IS_AUTHENTICATED, true);
    } catch (error) {
      if ((error as any)?.response?.status === 401) {
        commit(SET_ACCESS_TOKEN, {
          data: {
            token: '',
            expiresAt: 0,
          },
          loading: false,
          error,
        });
        commit(SET_IS_AUTHENTICATED, false);
        throw error;
      }
      commit(SET_ACCESS_TOKEN, { ...state.accessToken, loading: false, error });
    }
  },

  async [LOGOUT]({ commit }) {
    await AuthService.logout();
    commit(SET_ACCESS_TOKEN, {
      ...state.accessToken,
      data: {
        token: '',
        expiresAt: 0,
      },
    });
    commit(SET_IS_AUTHENTICATED, false);
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
