








import { Component, Emit, Prop, Vue } from 'vue-property-decorator';

@Component({
  name: 'Overlay',
})
export default class Overlay extends Vue {
  @Emit()
  change(value: boolean) {
    return value;
  }

  @Prop({ required: true }) value!: boolean;

  get overlay() {
    return this.value;
  }

  set overlay(value: boolean) {
    this.change(value);
  }
}
