






















import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
  name: 'Logo',
})
export default class Logo extends Vue {
  @Prop({ default: 'black' }) color!: string;
  @Prop({ default: 'normal' }) size!: 'normal' | 'lg';
}
