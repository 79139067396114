import get from 'lodash/get';
import set from 'lodash/set';

export function Memoize(target: any, key: string, descriptor: PropertyDescriptor) {
  const originalMethod = descriptor.value;
  const cache: Record<string, any> = {};

  descriptor.value = async function(...args: PropertyDescriptor['value']['arguments']) {
    const path = JSON.stringify(args);
    const cachedValue = get(cache, path) ?? undefined;
    if (cachedValue !== undefined) {
      return cachedValue;
    }
    const result = originalMethod.apply(this, args);
    set(cache, path, result);
    result.catch(() => {
      set(cache, path, undefined);
    });
    return result;
  };
}
