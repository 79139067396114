








import { Component, Vue } from 'vue-property-decorator';

@Component({
  name: 'SkypeIcon',
})
export default class SkypeIcon extends Vue {}
