import axios from 'axios';

import {
  IDataTableQuery,
  IPaymentMethod,
  IPaymentRequest,
  IResponse,
  PaymentMethod,
} from '@/interfaces';
import ApiService from '@/services/ApiService';
import {
  PAYMENT_REQUEST_PRE_APPROVE_STATUS,
  PAYMENT_REQUEST_PRE_REJECT_STATUS,
} from '@/utils/constants';

const PaymentMethodsService = {
  async getAllPaymentMethods() {
    return ApiService.http.get<void, { data: Record<PaymentMethod, IPaymentMethod> }>(
      '/api/payment-methods'
    );
  },

  async getPayments(
    params: {
      batch_id?: number;
      date_from?: string;
      date_to?: string;
      orderBy?: string;
      statuses?: number[];
      user_email?: string;
    } & (IDataTableQuery | any)
  ) {
    (this as any).cancel?.(); // TODO: Refactor
    return ApiService.http.get<
      void,
      IResponse<IPaymentRequest> & {
        is_balance_enough: boolean;
        is_credentials_set: boolean;
        have_no_active_requests: boolean;
        address_filled_in: boolean;
        manual_payments: boolean;
      }
    >('/api/payment-requests', {
      params,
      cancelToken: new axios.CancelToken((c: any) => ((PaymentMethodsService as any).cancel = c)),
    });
  },

  async createPaymentRequest(params: { amount?: number; tips?: number }) {
    return ApiService.http.post<void, { data: IPaymentRequest }>('/api/payment-requests', params);
  },

  async updatePaymentRequests(
    params: {
      request_id: number;
      bitmask: number;
      reject_reason?: string;
      premoderation?: {
        status:
          | typeof PAYMENT_REQUEST_PRE_APPROVE_STATUS
          | typeof PAYMENT_REQUEST_PRE_REJECT_STATUS;
        comment: string | null;
      };
    }[]
  ) {
    return ApiService.http.patch<
      void,
      {
        data: {
          requests: IPaymentRequest[];
          errors: {
            request_id: number;
            text: string;
          }[];
        };
      }
    >(`/api/payment-requests`, params);
  },
};

export default PaymentMethodsService;
