var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"tw-grid"},_vm._l((_vm.pages.filter(function (ref) {
    var visible = ref.visible;

    return visible;
})),function(item){return _c('router-link',{key:item.name,staticClass:"tw-flex tw-items-center tw-gap-2.5 tw-py-4 tw-px-5 tw-transition tw-text-black hover:tw-text-mybid-primary dark:hover:tw-text-mybid-primary",class:{
      'tw-font-bold tw-bg-mybid-primary-light dark:tw-bg-mybid-gray tw-text-mybid-primary':
        _vm.$route.path === item.path,
      'tw-justify-center': _vm.menuOpened,
      'tw-rounded-md': ['Clickadilla', 'Onclicka'].includes(_vm.env.VUE_APP_ORG_NAME),
      'dark:tw-text-mybid-primary':
        _vm.env.VUE_APP_ORG_NAME === 'Onclicka' && _vm.$route.path === item.path,
      'dark:tw-text-black': _vm.env.VUE_APP_ORG_NAME === 'Onclicka',
      'dark:tw-text-white': _vm.env.VUE_APP_ORG_NAME !== 'Onclicka',
    },attrs:{"to":item.path},nativeOn:{"click":function($event){return _vm.clickHandler($event)}}},[_c('div',{staticClass:"tw-fill-current",domProps:{"innerHTML":_vm._s(item.icon)}}),(!_vm.menuOpened)?_c('span',{staticClass:"tw-font-gotham tw-text-sm tw-whitespace-nowrap"},[_vm._v("\n      "+_vm._s(item.name)+"\n    ")]):_vm._e()])}),1)}
var staticRenderFns = []

export { render, staticRenderFns }