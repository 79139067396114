import Resize from '@/utils/resize';
const defaultTheme = require('tailwindcss/defaultTheme');

const Resizable = (function() {
  const resize = new Resize({
    xxl: '1440px',
    ...defaultTheme.screens,
  });
  resize.observe();
  resize.listen();
})();

export default Resizable;
