var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"tw-grid"},[_c('button',{staticClass:"tw-flex tw-items-center tw-py-4 tw-px-5  tw-transition tw-text-mybid-primary",class:{
      'tw-justify-center': _vm.menuOpened,
      'tw-justify-between': !_vm.menuOpened,
    },on:{"click":function($event){_vm.group = !_vm.group}}},[_c('span',{staticClass:"tw-flex tw-items-center tw-gap-2.5"},[_c('svg',{staticClass:"tw-fill-current",attrs:{"width":"14","height":"14","viewBox":"0 0 24 24"}},[_c('path',{attrs:{"d":"M12 1L21 5V11C21 16.55 17.16 21.74 12 23C6.84 21.74 3 16.55 3 11V5L12 1M16 14H8V15.5C8 15.77 8.19 15.96 8.47 16L8.57 16H15.43C15.74 16 15.95 15.84 16 15.59L16 15.5V14M17 8L17 8L14.33 10.67L12 8.34L9.67 10.67L7 8L7 8L8 13H16L17 8Z"}})]),(!_vm.menuOpened)?_c('span',{staticClass:"tw-font-gotham tw-text-sm"},[_vm._v("Admin")]):_vm._e()]),(!_vm.menuOpened)?_c('svg',{staticClass:"tw-fill-current",attrs:{"width":"14","height":"14","viewBox":"0 0 24 24"}},[_c('path',{attrs:{"d":_vm.group
            ? 'M7.41,15.41L12,10.83L16.59,15.41L18,14L12,8L6,14L7.41,15.41Z'
            : 'M7.41,8.58L12,13.17L16.59,8.58L18,10L12,16L6,10L7.41,8.58Z'}})]):_vm._e()]),_c('transition',{attrs:{"name":"slide"}},[(_vm.group)?_c('div',_vm._l((_vm.pages.filter(function (ref) {
                var visible = ref.visible;

                return visible;
})),function(item){return _c('router-link',{key:item.name,staticClass:"tw-flex tw-items-center tw-gap-2.5 tw-py-4 tw-px-5  tw-transition tw-text-black hover:tw-text-mybid-primary dark:hover:tw-text-mybid-primary",class:{
          'tw-font-bold tw-bg-blue-50 dark:tw-bg-mybid-gray tw-text-mybid-primary':
            _vm.$route.path === item.path,
          'tw-justify-center': _vm.menuOpened,
          'tw-rounded-md': ['Clickadilla', 'Onclicka'].includes(_vm.env.VUE_APP_ORG_NAME),
          'dark:tw-text-black': _vm.env.VUE_APP_ORG_NAME === 'Onclicka',
          'dark:tw-text-white': _vm.env.VUE_APP_ORG_NAME !== 'Onclicka',
        },attrs:{"to":item.path}},[_c('div',{staticClass:"tw-fill-current",domProps:{"innerHTML":_vm._s(item.icon)}}),(!_vm.menuOpened)?_c('span',{staticClass:"tw-font-gotham tw-text-sm tw-whitespace-nowrap"},[_vm._v("\n          "+_vm._s(item.name)+"\n        ")]):_vm._e(),(item.badge && _vm.permissions.metas)?_c('Badge',{attrs:{"inline":"","content":item.badge}}):_vm._e()],1)}),1):_vm._e()])],1)}
var staticRenderFns = []

export { render, staticRenderFns }