





























import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
  name: 'Badge',
})
export default class Badge extends Vue {
  @Prop({ default: false, type: Boolean }) dot!: boolean;
  @Prop({ default: 'primary' }) color!:
    | 'primary'
    | 'primary-light'
    | 'secondary'
    | 'secondary-light';
  @Prop({ default: false, type: Boolean }) inline!: boolean;
  @Prop({ default: null }) content!: string;
}
