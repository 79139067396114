import set from 'lodash/set';
import Vue from 'vue';
import Component from 'vue-class-component';

@Component
export default class apiMixins extends Vue {
  errors: any = {};
  form = {};
  private timerID!: ReturnType<typeof setTimeout>;

  errorHandler(error: any, errorsRef?: any) {
    if (typeof error === 'undefined') return;
    if (error?.response?.status === 403) {
      this.$notify.$emit('error', error?.response?.data.message);
    }
    const { errors = {}, message = null } = error?.response?.data?.data ?? {
      errors: {},
      message: null,
    };
    if (errors?.hasOwnProperty('remote_service')) {
      this.$notify.$emit('error', message);
      throw error;
    }
    if (
      this.form &&
      Object.keys(errors).length > 0 &&
      this.form.hasOwnProperty(Object.keys(errors)[0])
    ) {
      this.errors = errors;
      throw error;
    }
    if (errors.options || errors.additional_options) {
      this.errors = { ...errors.options, ...errors.additional_options };
      throw error;
    }
    if (message) {
      this.$notify.$emit('error', message);
    }
    this.errors = errors;
    if (errorsRef) {
      errorsRef.value = errors;
    }
    throw error;
  }
  promiseWrapper<T>(
    promise: Promise<T>,
    loadingFlagName = 'dataIsLoading',
    customHandler?: (e: any) => void
  ): Promise<T> {
    set(this, loadingFlagName, true);
    this.errors = {};
    return promise
      .catch<T>(((error: Error) => {
        customHandler ? customHandler(error) : this.errorHandler(error);
      }) as (reason: any) => T | PromiseLike<T>)
      .finally(() => set(this, loadingFlagName, false));
  }
  debounce<T extends Function>(cb: T, delay: number, ...args: Array<any>) {
    clearTimeout(this.timerID);
    this.timerID = setTimeout(() => cb(...args), delay);
  }
}
