import { ActionTree, GetterTree, MutationTree } from 'vuex';

import { PaymentsState, RootState } from '@/interfaces';
import PaymentMethodsService from '@/services/PaymentMethodsService';
import { FETCH_PAYMENT_METHODS } from '@/store/types/actions';
import { SET_PAYMENT_METHODS } from '@/store/types/mutations';

const state: PaymentsState = {
  paymentMethods: {
    loading: false,
    error: null,
    data: [],
  },
  paymentDetails: [
    {
      text: 'Bitcoin address *',
      type: 'text',
      detail: 'bitcoinWallet',
      method: 'bitcoin',
      isWallet: true,
      disabled: () => false,
      visible: () => true,
    },
    {
      text: 'Capitalist wallet *',
      type: 'text',
      method: 'capitalist',
      detail: 'capitalistWallet',
      isWallet: true,
      disabled: () => false,
      visible: () => true,
    },
    {
      text: 'Paxum wallet *',
      type: 'text',
      method: 'paxum',
      detail: 'paxumEmail',
      isWallet: true,
      disabled: () => false,
      visible: () => true,
    },
    {
      text: 'Paypal address *',
      type: 'text',
      detail: 'paypal_email',
      method: 'paypal',
      isWallet: true,
      disabled: () => false,
      visible: () => true,
    },
    {
      text: 'Paypal address *',
      type: 'text',
      detail: 'paypal_business_email',
      method: 'paypal_business',
      isWallet: true,
      disabled: () => false,
      visible: () => true,
    },
    {
      text: 'USDC address *',
      type: 'text',
      detail: 'usdcWallet',
      method: 'usdc',
      isWallet: true,
      disabled: () => process.env.VUE_APP_ORG_NAME !== 'Clickadilla',
      visible: () => process.env.VUE_APP_ORG_NAME === 'Clickadilla',
    },
    {
      text: 'USDT (TRC-20) address *',
      type: 'text',
      detail: 'usdtTrc20Wallet',
      method: 'usdt_trc-20',
      isWallet: true,
      disabled: () => false,
      visible: () => true,
    },
    {
      text: 'Webmoney wallet *',
      type: 'text',
      detail: 'webmoneyWallet',
      method: 'webmoney',
      isWallet: true,
      disabled: () => false,
      visible: () => true,
    },
    {
      text: 'Account number/IBAN *',
      type: 'text',
      detail: 'account_number',
      method: 'wire_transfer',
      isWallet: false,
      disabled: () => false,
      visible: () => true,
    },
    {
      text: 'Company name *',
      type: 'text',
      detail: 'account_name',
      method: 'wire_transfer',
      isWallet: true,
      disabled: () => false,
      visible: () => true,
    },
    {
      text: 'Bank name *',
      type: 'text',
      detail: 'bank_name',
      method: 'wire_transfer',
      isWallet: false,
      disabled: () => false,
      visible: () => true,
    },
    {
      text: 'Bank SWIFT/BIC *',
      type: 'text',
      detail: 'bank_swift',
      method: 'wire_transfer',
      isWallet: false,
      disabled: profile => !!profile.payment_method_details?.bank_sepa,
      visible: () => true,
    },
    {
      text: 'Account type *',
      type: 'select',
      detail: 'type',
      method: 'wire_transfer',
      isWallet: false,
      disabled: () => false,
      visible: () => true,
    },
    {
      text: 'Currency *',
      type: 'select',
      detail: 'currency',
      method: 'wire_transfer',
      isWallet: false,
      disabled: () => false,
      visible: () => true,
    },
    {
      text: 'Bank city *',
      type: 'text',
      detail: 'bank_city',
      method: 'wire_transfer',
      isWallet: false,
      disabled: () => false,
      visible: () => true,
    },
    {
      text: 'Bank state *',
      type: 'text',
      detail: 'bank_state',
      method: 'wire_transfer',
      isWallet: false,
      disabled: () => false,
      visible: () => true,
    },
    {
      text: 'Bank zip code *',
      type: 'text',
      detail: 'bank_zip_code',
      method: 'wire_transfer',
      isWallet: false,
      disabled: () => false,
      visible: () => true,
    },
    {
      text: 'Bank address',
      type: 'text',
      detail: 'bank_address',
      method: 'wire_transfer',
      isWallet: false,
      disabled: () => false,
      visible: () => true,
    },
    {
      text: 'Bank country *',
      type: 'select',
      detail: 'bank_country',
      method: 'wire_transfer',
      isWallet: false,
      disabled: () => false,
      visible: () => true,
    },
  ],
};

const getters: GetterTree<PaymentsState, RootState> = {
  paymentMethods: state => state.paymentMethods,
  paymentDetails: state => state.paymentDetails,
};

const mutations: MutationTree<PaymentsState> = {
  [SET_PAYMENT_METHODS]: (state, payload: PaymentsState['paymentMethods']) => {
    state.paymentMethods = payload;
  },
};

const actions: ActionTree<PaymentsState, RootState> = {
  async [FETCH_PAYMENT_METHODS]({ state, commit }) {
    try {
      commit(SET_PAYMENT_METHODS, { ...state.paymentMethods, loading: false, error: null });
      const response = await PaymentMethodsService.getAllPaymentMethods();
      const data = Object.values(response.data);
      commit(SET_PAYMENT_METHODS, {
        ...state.paymentMethods,
        data:
          process.env.VUE_APP_ORG_NAME === 'MyBid'
            ? data.filter(({ value }) => !['usdc'].includes(value))
            : data,
        loading: false,
      });
    } catch (error) {
      commit(SET_PAYMENT_METHODS, {
        ...state.paymentMethods,
        loading: false,
        error,
      });
    }
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
