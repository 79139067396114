import {
  ApplicationState,
  IBroadcastEvent,
  IDataTableQuery,
  INotification,
  IResponse,
} from '@/interfaces';
import ApiService from '@/services/ApiService';
import store from '@/store';
import {
  SET_BROADCAST_EVENTS,
  SET_MENU_NOTIFICATIONS,
  SET_NOTIFICATIONS_COUNT,
} from '@/store/types/mutations';

class NotificationService {
  async getNotifications(params: Partial<IDataTableQuery>) {
    return ApiService.http.get<void, IResponse<INotification>>('/api/notifications', {
      params,
    });
  }

  async getNotificationsCount() {
    return ApiService.http.get<
      void,
      { data: { title: string; type: INotification['type']; count: number }[] }
    >('/api/notifications/count');
  }

  async updateNotifications(payload: {
    ids: INotification['id'][];
    status: INotification['status'];
  }) {
    return ApiService.http.patch<void, { data: INotification[] }>('/api/notifications', payload);
  }

  async subscribeToNotifications() {
    return ApiService.socket
      .private(`notifications.${store.getters.user.data.uuid}`)
      .listen('.notifications', (notification: INotification) => {
        store.commit(SET_MENU_NOTIFICATIONS, {
          ...store.getters.menuNotifications,
          data: {
            ...store.getters.menuNotifications.data,
            data: [notification, ...store.getters.menuNotifications.data.data],
            meta: {
              ...store.getters.menuNotifications.data.meta,
              total: store.getters.menuNotifications.data.meta.total + 1,
            },
          },
        });
        const index = (store.getters
          .notificationsCount as ApplicationState['notificationsCount']).data.findIndex(
          ({ type }) => type === notification.type
        );
        if (index === -1) return;
        store.commit(SET_NOTIFICATIONS_COUNT, {
          ...(store.getters.notificationsCount as ApplicationState['notificationsCount']),
          data: [
            ...store.getters.notificationsCount.data.slice(0, index),
            {
              ...store.getters.notificationsCount.data[index],
              count: store.getters.notificationsCount.data[index].count + 1,
            },
            ...store.getters.notificationsCount.data.slice(index + 1),
          ],
        });
      });
  }

  async subscribeToBroadcastEvents() {
    return ApiService.socket
      .private(`service-broadcast-channel.${store.getters.user.data.uuid}`)
      .listen('.service-broadcast-channel', (broadcastEvent: IBroadcastEvent) =>
        store.commit(SET_BROADCAST_EVENTS, [broadcastEvent, ...store.getters.broadcastEvents])
      );
  }
}

export default new NotificationService();
