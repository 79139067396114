import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';

import {
  ApplicationState,
  IDataTableQuery,
  INotification,
  NotificationsQuery,
  NotificationType,
} from '@/interfaces';
import {
  FETCH_NOTIFICATIONS,
  GET_NOTIFICATIONS_COUNT,
  UPDATE_NOTIFICATIONS,
} from '@/store/types/actions';

@Component
export default class NotificationMixin extends Vue {
  @Prop({ required: true }) permissions!: { update: boolean };

  @Getter profileNotifications!: ApplicationState['profileNotifications'];
  @Getter menuNotifications!: ApplicationState['menuNotifications'];
  @Getter notificationsCount!: ApplicationState['notificationsCount'];

  @Action(FETCH_NOTIFICATIONS) getNotifications!: (
    params: Partial<IDataTableQuery> & { section: 'menu' | 'profile' }
  ) => void;
  @Action(GET_NOTIFICATIONS_COUNT) getNotificationsCount!: (
    params: Partial<IDataTableQuery>
  ) => void;
  @Action(UPDATE_NOTIFICATIONS) updateNotifications!: (payload: {
    ids: number[];
    status: INotification['status'];
  }) => void;

  texts: Record<NotificationType | 'default', (notification: INotification) => string> = {
    default: () => ``,
    revshare_moderation_request: () => `You have new pending revshare moderation request`,
    dynamic_revshare_moderation_request: () =>
      `You have new pending dynamic revshare moderation request`,
    dynamic_revshare_action: () => ``,
    stats_report: () => ``,
    tag_not_installed: () => ``,
    publisher_tag_or_spot_copied: () => ``,
    referral_registered: () => ``,
    user_email_changed: () => ``,
    spots_list_report: () => ``,
    without_group: () => ``,
  };
  actions: Record<
    NotificationType | 'default',
    (notification: INotification, onComplete?: Function) => void
  > = {
    default: (notification, onComplete) => {
      this.markNotifications([notification]);
      this.$router.push({
        path: '/profile',
        query: {
          section: 'notifications',
        },
      });
      if (onComplete) {
        onComplete();
      }
    },
    revshare_moderation_request: (notification, onComplete) => {
      this.markNotifications([notification]);
      this.$router.push({
        path: '/admin/revshare-moderation',
      });
      if (onComplete) {
        onComplete();
      }
    },
    dynamic_revshare_moderation_request: (notification, onComplete) => {
      this.markNotifications([notification]);
      this.$router.push({
        path: '/admin/revshare-moderation',
        query: {
          section: 'dynamic-revshare',
        },
      });
      if (onComplete) {
        onComplete();
      }
    },
    dynamic_revshare_action: (notification, onComplete) => {
      this.markNotifications([notification]);
      this.$router.push({
        path: '/profile',
        query: {
          section: 'notifications',
        },
      });
      if (onComplete) {
        onComplete();
      }
    },
    stats_report: (notification, onComplete) => {
      this.markNotifications([notification]);
      this.$router.push({
        path: '/profile',
        query: {
          section: 'notifications',
        },
      });
      if (onComplete) {
        onComplete();
      }
    },
    spots_list_report: (notification, onComplete) => {
      this.markNotifications([notification]);
      this.$router.push({
        path: '/profile',
        query: {
          section: 'notifications',
        },
      });
      if (onComplete) {
        onComplete();
      }
    },
    tag_not_installed: (notification, onComplete) => {
      this.markNotifications([notification]);
      this.$router.push({
        path: '/profile',
        query: {
          section: 'notifications',
        },
      });
      if (onComplete) {
        onComplete();
      }
    },
    publisher_tag_or_spot_copied: (notification, onComplete) => {
      this.markNotifications([notification]);
      this.$router.push({
        path: '/profile',
        query: {
          section: 'notifications',
        },
      });
      if (onComplete) {
        onComplete();
      }
    },
    referral_registered: (notification, onComplete) => {
      this.markNotifications([notification]);
      this.$router.push({
        path: '/profile',
        query: {
          section: 'notifications',
        },
      });
      if (onComplete) {
        onComplete();
      }
    },
    user_email_changed: (notification, onComplete) => {
      this.markNotifications([notification]);
      this.$router.push({
        path: '/profile',
        query: {
          section: 'notifications',
        },
      });
      if (onComplete) {
        onComplete();
      }
    },
    without_group: (notification, onComplete) => {
      this.markNotifications([notification]);
      this.$router.push({
        path: '/profile',
        query: {
          section: 'notifications',
        },
      });
      if (onComplete) {
        onComplete();
      }
    },
  };
  query: NotificationsQuery = {
    page: 1,
    limit: 100,
    status: null,
    type: null,
  };

  get unmarkedNotifications() {
    return this.menuNotifications.data.data.filter(({ status }) => status === 'unmarked');
  }

  get unmarkedProfileNotifications() {
    return this.profileNotifications.data.data.filter(({ status }) => status === 'unmarked');
  }

  getNotificationBody(notification: INotification) {
    if (notification.body) {
      return notification.body;
    }
    if (notification.type && this.texts.hasOwnProperty(notification.type)) {
      return this.texts[notification.type](notification);
    }
    return this.texts.default(notification);
  }

  markNotifications(notifications: INotification[]) {
    if (!this.permissions.update) return;
    this.updateNotifications({ ids: notifications.map(({ id }) => id), status: 'marked' });
  }

  updateOptions(query: NotificationsQuery & { section: 'menu' | 'profile' }) {
    this.query = query;
    this.getNotifications(query);
  }
}
