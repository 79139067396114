import { CancelRequestErrorHandler } from '@/errors/http/CancelRequestErrorHandler';
import { ForbiddenErrorHandler } from '@/errors/http/ForbiddenErrorHandler';
import { NetworkErrorHandler } from '@/errors/http/NetworkErrorHandler';
import { ServerErrorHandler } from '@/errors/http/ServerErrorHandler';
import { UnauthorizedErrorHandler } from '@/errors/http/UnauthorizedErrorHandler';

let handlers = [
  new NetworkErrorHandler(),
  new ServerErrorHandler(),
  new UnauthorizedErrorHandler(),
  new ForbiddenErrorHandler(),
  new CancelRequestErrorHandler(),
];

export const errorHandler = async (error: Error) => {
  for await (let handler of handlers) {
    if (handler.canHandle(error)) {
      await handler.handle();
      break;
    }
  }
};
