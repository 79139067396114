





































import { Component, Emit, Prop, Vue } from 'vue-property-decorator';

import SidebarNotifications from '@/components/v2/Sidebar/Notifications.vue';
import { ApplicationState, IDataTableQuery, INotification, UserState } from '@/interfaces';

@Component({
  name: 'SidebarActions',
  components: { SidebarNotifications },
})
export default class SidebarActions extends Vue {
  @Emit()
  logout() {
    return;
  }

  @Emit()
  actions(type: INotification['type'], notification: INotification) {
    return { type, notification };
  }

  @Emit()
  toggleTheme() {
    return;
  }

  @Emit()
  getNotifications(query: Pick<IDataTableQuery, 'page'> & { section: 'menu' | 'profile' }) {
    return query;
  }

  @Emit()
  markNotifications(notifications: INotification[]) {
    return notifications;
  }

  @Emit()
  resetUser() {
    return;
  }

  @Emit()
  getNotificationBody(notification: INotification) {
    return notification;
  }

  @Prop({ required: true }) user!: UserState['user'];
  @Prop({ required: true }) menuOpened!: boolean;
  @Prop({ required: true }) permissions!: Record<'update' | 'viewAny', boolean>;
  @Prop({ required: true }) isDarkTheme!: boolean;
  @Prop({ required: true }) notifications!: ApplicationState['menuNotifications'];
  @Prop({ required: true }) isLoggedAsUser!: boolean;
  @Prop({ required: true }) notificationsCount!: ApplicationState['notificationsCount'];
  @Prop({ required: true }) unmarkedNotifications!: INotification[];

  get env() {
    return process.env;
  }

  get items() {
    return [
      ...(process.env.VUE_APP_ORG_NAME !== 'Onclicka'
        ? [
            {
              name: 'theme',
              text: this.isDarkTheme ? 'Light' : 'Dark',
              icon: `
          <svg width="14" height="14" viewBox="0 0 14 14">
            <path
              d="M13.8019 8.37615C13.6426 8.27788 13.437 8.29593 13.2991 8.42197C12.2949 9.33286 10.9958 9.83476 9.64198 9.83476C6.63705 9.83476 4.19225 7.38995 4.19225 4.38503C4.19225 3.03108 4.69415 1.73215 5.60504 0.727906C5.73065 0.589478 5.74956 0.3844 5.65086 0.225144C5.55217 0.0663155 5.35895 -0.00738443 5.18047 0.0430306C2.13004 0.906815 0 3.72664 0 6.90022C0 10.83 3.19698 14.0269 7.12668 14.0269C10.3003 14.0269 13.1202 11.8969 13.984 8.84654C14.0351 8.66635 13.9607 8.47473 13.8019 8.37615Z"
            />
            <path
              d="M6.94211 5.55952L7.93524 6.05566L8.43138 7.0489C8.5022 7.19096 8.64757 7.28058 8.8064 7.28058C8.96533 7.28058 9.11017 7.19096 9.18141 7.0489L9.67809 6.05566L10.6708 5.55952C10.8129 5.48828 10.9025 5.34334 10.9025 5.18451C10.9025 5.02568 10.8129 4.88074 10.6708 4.80949L9.67809 4.31325L9.18141 3.32011C9.03893 3.036 8.57312 3.036 8.43138 3.32011L7.93524 4.31325L6.94211 4.80949C6.80005 4.88031 6.71033 5.02568 6.71033 5.18451C6.71033 5.34334 6.80005 5.48871 6.94211 5.55952Z"
            />
            <path
              d="M11.3188 2.28885H11.7381V2.70808C11.7381 2.93976 11.9256 3.12732 12.1573 3.12732C12.389 3.12732 12.5766 2.93976 12.5766 2.70808V2.28885H12.9958C13.2275 2.28885 13.415 2.10139 13.415 1.86961C13.415 1.63794 13.2275 1.45049 12.9958 1.45049H12.5766V1.03125C12.5766 0.799469 12.389 0.612015 12.1573 0.612015C11.9256 0.612015 11.7381 0.799469 11.7381 1.03125V1.45049H11.3188C11.0872 1.45049 10.8997 1.63794 10.8997 1.86961C10.8997 2.10139 11.0872 2.28885 11.3188 2.28885Z"
            />
          </svg>
        `,
              handler: this.toggleTheme,
              visible: true,
            },
          ]
        : []),
      {
        name: 'reset_user',
        text: 'Reset user',
        icon: `
          <svg width="14" height="14" viewBox="0 0 24 24">
            <path
              d="M12,4A4,4 0 0,1 16,8C16,9.95 14.6,11.58 12.75,11.93L8.07,7.25C8.42,5.4 10.05,4 12,4M12.28,14L18.28,20L20,21.72L18.73,23L15.73,20H4V18C4,16.16 6.5,14.61 9.87,14.14L2.78,7.05L4.05,5.78L12.28,14M20,18V19.18L15.14,14.32C18,14.93 20,16.35 20,18Z"
            />
          </svg>
        `,
        handler: this.resetUser,
        visible: this.isLoggedAsUser,
      },
      {
        name: 'logout',
        text: `
          <span>Logout</span>
          <span class="tw-w-44 tw-text-xs tw-overflow-hidden tw-overflow-ellipsis tw-text-gray-400 tw-text-left">${this.user.data.email}</span>
        `,
        icon: `
          <svg width="14" height="14" viewBox="0 0 14 14">
            <g clip-path="url(#clip0_1793_5453)">
              <path
                d="M11.1996 14.0001C12.746 14.0001 13.9996 12.7465 13.9996 11.2001L13.9996 7.70009L6.58951 7.70009L8.19454 9.30511C8.46789 9.57848 8.46789 10.0217 8.19454 10.2951C7.92119 10.5684 7.47795 10.5684 7.2046 10.2951L4.40509 7.49555L4.40005 7.49044C4.33516 7.42443 4.28609 7.34869 4.2527 7.26805C4.21868 7.18608 4.19985 7.09634 4.19957 7.00219L4.19957 7.00009L4.19957 6.99799C4.20013 6.80787 4.2765 6.63553 4.40005 6.50974L4.40509 6.50463L7.2046 3.70512C7.47795 3.43177 7.92119 3.43177 8.19454 3.70512C8.46789 3.97847 8.46789 4.42171 8.19454 4.69506L6.58951 6.30009L13.9996 6.30009L13.9996 2.80009C13.9996 1.25372 12.746 9.16816e-05 11.1996 9.15464e-05L2.79957 9.0812e-05C1.2532 9.06768e-05 -0.000425893 1.25372 -0.000426029 2.80009L-0.000426763 11.2001C-0.000426898 12.7465 1.2532 14.0001 2.79957 14.0001L11.1996 14.0001Z"
              />
            </g>
            <defs>
              <clipPath id="clip0_1793_5453">
                <rect width="14" height="14" rx="7" fill="white" />
              </clipPath>
            </defs>
          </svg>
        `,
        handler: this.logout,
        visible: true,
      },
    ];
  }

  clickHandler(handler: () => any) {
    if (process.env.VUE_APP_ORG_NAME === 'MyBid') {
      window.fbq('track', 'ViewContent');
    }

    window.dataLayer.push({
      event: 'clickmenu_pub',
    });

    handler();
  }
}
