import store from '@/store';
import { SET_ERROR } from '@/store/types/mutations';

export class NetworkErrorHandler {
  canHandle(error: Error) {
    return error.message === 'Network Error' && window.navigator.onLine;
  }

  async handle() {
    if (store.getters.environment === 'production') {
      await store.commit(SET_ERROR, 'network_error');
    }
  }
}
