































import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
  name: 'Loader',
})
export default class Loader extends Vue {
  @Prop({ default: 'auto' }) size!: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'auto';
  @Prop({
    default: 'spinning',
    type: String,
  })
  animate!: 'spinning' | 'progress';
}
