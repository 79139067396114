

































































import { Component, Emit, Prop, Vue } from 'vue-property-decorator';

import Button from '@/components/v2/Button.vue';
import Info from '@/components/v2/Info.vue';
import Overlay from '@/components/v2/Overlay.vue';
import SidebarActions from '@/components/v2/Sidebar/Actions.vue';
import SidebarAdmin from '@/components/v2/Sidebar/Admin.vue';
import SidebarHeader from '@/components/v2/Sidebar/Header.vue';
import SidebarUser from '@/components/v2/Sidebar/User.vue';
import {
  ApplicationState,
  IDataTableQuery,
  INotification,
  ISidebarPermissions,
  Manager,
  UserState,
} from '@/interfaces';

@Component({
  name: 'Sidebar',
  components: { Button, Info, Overlay, SidebarActions, SidebarAdmin, SidebarHeader, SidebarUser },
})
export default class Sidebar extends Vue {
  @Emit()
  logout() {
    return;
  }

  @Emit()
  actions(type: INotification['type'], notification: INotification) {
    return { type, notification };
  }

  @Emit()
  toggleMenu() {
    return;
  }

  @Emit()
  toggleTheme() {
    return;
  }

  @Emit()
  getNotifications(query: Pick<IDataTableQuery, 'page'>) {
    return { ...query, section: 'menu' };
  }

  @Emit()
  markNotifications(notifications: INotification[]) {
    return notifications;
  }

  @Emit()
  resetUser() {
    return;
  }

  @Emit()
  getNotificationBody(notification: INotification) {
    return notification;
  }

  @Prop({ required: true }) user!: UserState['user'];
  @Prop({ required: true }) metaData!: ApplicationState['metaData'];
  @Prop({ required: true }) menuOpened!: boolean;
  @Prop({ required: true }) userManager!: Manager;
  @Prop({ required: true }) permissions!: {
    sidebar: ISidebarPermissions;
    notification: Record<'update' | 'viewAny', boolean>;
  };
  @Prop({ required: true }) isDarkTheme!: boolean;
  @Prop({ required: true }) notifications!: ApplicationState['menuNotifications'];
  @Prop({ required: true }) isLoggedAsUser!: boolean;
  @Prop({ required: true }) notificationsCount!: ApplicationState['notificationsCount'];
  @Prop({ required: true }) unmarkedNotifications!: INotification[];

  variant = false;

  changeVariant(variant: boolean) {
    this.variant = variant;
  }

  get env() {
    return process.env;
  }
}
