








import { Component, Vue } from 'vue-property-decorator';

@Component({
  name: 'TelegramIcon',
})
export default class TelegramIcon extends Vue {}
