import ApiService from '@/services/ApiService';
import SpotService from '@/services/SpotService';

class TokenService {
  async setTokenToServices(token: string) {
    [ApiService, SpotService].forEach(service => {
      (service as any).addHeader({ name: 'Authorization', value: `Bearer ${token}` });
    });
  }

  async removeTokenFromServices() {
    [ApiService, SpotService].forEach(service => {
      (service as any).removeHeader('Authorization');
    });
  }

  async createTokenForPublishers() {
    return ApiService.http.post<void, { data: { token: string } }>('/api/tokens');
  }

  async getTokenForPublishers() {
    return ApiService.http.get<void, { data: { token: string } }>('/api/tokens');
  }

  async disconnectSocket() {
    return ApiService.socket.disconnect();
  }
}

export default new TokenService();
