import { ICountry, ILabel, IUABrowser } from '@/interfaces';
import ApiService from '@/services/ApiService';

class CommonService {
  async getAdFormats() {
    return ApiService.http.get<void, { data: AdFormat[] }>('/api/adformats');
  }

  async getLanguages() {
    return ApiService.http.get<void, { data: string[] }>('/api/languages');
  }

  async getCountries() {
    return ApiService.http.get<void, { data: ICountry[] }>('/api/countries');
  }

  async getBillingLabels() {
    return ApiService.http.get<void, { data: ILabel[] }>('/api/billing-labels');
  }

  async getExchangeRates(params: { currency: 'bitcoin' }) {
    return ApiService.http.get<void, { data: { rate: string } }>('/api/exchange-rates', {
      params,
    });
  }

  async getUABrowsers() {
    return new Promise<{ data: IUABrowser[] }>(resolve =>
      resolve({
        data: [
          { name: '2345Explorer' },
          { name: '360 Browser' },
          { name: 'Amaya' },
          { name: 'Android Browser' },
          { name: 'Arora' },
          { name: 'Avant' },
          { name: 'Avast' },
          { name: 'AVG' },
          { name: 'BIDUBrowser' },
          { name: 'Baidu' },
          { name: 'Basilisk' },
          { name: 'Blazer' },
          { name: 'Bolt' },
          { name: 'Brave' },
          { name: 'Bowser' },
          { name: 'Camino' },
          { name: 'Chimera' },
          { name: 'Chrome Headless' },
          { name: 'Chrome WebView' },
          { name: 'Chrome', isPopular: true },
          { name: 'Chromium' },
          { name: 'Comodo Dragon' },
          { name: 'Dillo' },
          { name: 'Dolphin' },
          { name: 'Doris' },
          { name: 'Edge' },
          { name: 'Electron' },
          { name: 'Epiphany' },
          { name: 'Facebook' },
          { name: 'Falkon' },
          { name: 'Fennec' },
          { name: 'Firebird' },
          { name: 'Firefox [Reality]' },
          { name: 'Flock' },
          { name: 'Flow' },
          { name: 'GSA' },
          { name: 'GoBrowser' },
          { name: 'ICE Browser' },
          { name: 'IE', isPopular: true },
          { name: 'IEMobile' },
          { name: 'IceApe' },
          { name: 'IceCat' },
          { name: 'IceDragon' },
          { name: 'Iceweasel' },
          { name: 'Instagram' },
          { name: 'Iridium' },
          { name: 'Iron' },
          { name: 'Jasmine' },
          { name: 'K-Meleon' },
          { name: 'Kindle' },
          { name: 'Klar' },
          { name: 'Konqueror' },
          { name: 'LBBROWSER' },
          { name: 'Line' },
          { name: 'Links' },
          { name: 'Lunascape' },
          { name: 'Lynx' },
          { name: 'MIUI Browser' },
          { name: 'Maemo Browser' },
          { name: 'Maemo' },
          { name: 'Maxthon' },
          { name: 'MetaSr Midori' },
          { name: 'Minimo' },
          { name: 'Mobile Safari' },
          { name: 'Mosaic' },
          { name: 'Mozilla', isPopular: true },
          { name: 'NetFront' },
          { name: 'NetSurf' },
          { name: 'Netfront' },
          { name: 'Netscape' },
          { name: 'NokiaBrowser' },
          { name: 'Obigo' },
          { name: 'Oculus Browser' },
          { name: 'OmniWeb' },
          { name: 'Opera Coast' },
          { name: 'Opera [Mini/Mobi/Tablet]', isPopular: true },
          { name: 'PaleMoon' },
          { name: 'PhantomJS' },
          { name: 'Phoenix' },
          { name: 'Polaris' },
          { name: 'Puffin' },
          { name: 'QQ' },
          { name: 'QQBrowser' },
          { name: 'QQBrowserLite' },
          { name: 'Quark' },
          { name: 'QupZilla' },
          { name: 'RockMelt' },
          { name: 'Safari', isPopular: true },
          { name: 'Sailfish Browser' },
          { name: 'Samsung Browser' },
          { name: 'SeaMonkey' },
          { name: 'Silk' },
          { name: 'Skyfire' },
          { name: 'Sleipnir' },
          { name: 'Slim' },
          { name: 'SlimBrowser' },
          { name: 'Swiftfox' },
          { name: 'Tesla' },
          { name: 'Tizen Browser' },
          { name: 'UCBrowser' },
          { name: 'UP.Browser' },
          { name: 'Vivaldi' },
          { name: 'Waterfox' },
          { name: 'WeChat' },
          { name: 'Weibo' },
          { name: 'Yandex' },
          { name: 'baidu' },
          { name: 'iCab' },
          { name: 'w3m' },
          { name: 'Whale Browser' },
        ],
      })
    );
  }
}

export default new CommonService();
