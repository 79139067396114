import axios from 'axios';
import Echo from 'laravel-echo';
import socketIO from 'socket.io-client';

import { errorHandler } from '@/errors';
import store from '@/store';
import { REFRESH } from '@/store/types/actions';

class ApiService {
  http = axios.create({
    baseURL: process.env.VUE_APP_BASEURL,
    withCredentials: true,
  });
  socket!: Echo;

  constructor() {
    this.http.defaults.headers.common.Accept = 'application/json';
    this.http.defaults.headers.common['Content-Type'] = 'application/json;charset=UTF-8';
    this.http.interceptors.request.use(
      request =>
        new Promise(async resolve => {
          const isTokenExpired = store.getters.accessToken.data.expiresAt < Date.now();
          if (
            !request.url?.includes('api/auth/refresh') &&
            isTokenExpired &&
            store.getters.isAuthenticated
          ) {
            await store.dispatch(REFRESH);
            request.headers.common.Authorization = `Bearer ${store.getters.accessToken.data.token}`;
          }
          resolve(request);
        })
    );
    this.http.interceptors.response.use(
      response => response.data,
      error => {
        errorHandler(error);
        return Promise.reject(error);
      }
    );
  }

  addHeader({ name, value }: { name: string; value: string }) {
    this.http.defaults.headers.common[name] = value;
  }

  removeHeader(name: string) {
    delete this.http.defaults.headers.common[name];
  }

  initSocket(config: {
    broadcaster: string;
    host: string;
    auth: {
      headers: {
        Authorization: string;
      };
    };
  }) {
    window.io = socketIO;

    this.socket = new Echo(config);
  }
}

export default new ApiService();
