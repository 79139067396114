import { IAuthResponse, IGoogleAuthQuery, IProfile, IUser } from '@/interfaces';
import ApiService from '@/services/ApiService';

class UserService {
  async resetPassword(params: { email: string }) {
    return ApiService.http.post<void, { data: { message: string } }>('/api/password/email', params);
  }

  async getProfile() {
    return ApiService.http.get<void, { data: IProfile }>('/api/profile');
  }

  async updateProfile(profile: IProfile) {
    return ApiService.http.put<void, { data: IProfile }>('/api/profile', profile);
  }

  async getAuthenticatedUser() {
    return ApiService.http.post<void, { data: IUser }>('/api/auth/me');
  }

  async updatePassword(params: {
    email: string;
    token: string;
    password: string;
    password_confirmation?: string;
  }) {
    return ApiService.http.post<void, void>('/api/password/reset', params);
  }

  async resendVerifyEmail() {
    return ApiService.http.get<void, { data: { message: string } }>('/api/auth/email/resend');
  }

  async verifyPassword(params: { email?: string; signature?: string; accessToken?: string }) {
    return ApiService.http.get<void, { data: { message: string } }>('/api/profile/change/confirm', {
      params,
    });
  }

  async verifyEmail(params: { email?: string; signature?: string; accessToken?: string }) {
    return ApiService.http.get<void, { data: { message: string } }>(`/api/auth/email/verify`, {
      params,
    });
  }

  async changeEmail(params: object) {
    return ApiService.http.get<void, { data: { message: string } }>(`/api/email/change/confirm`, {
      params,
    });
  }

  async verifyChangeEmail(params: object) {
    return ApiService.http.get<void, { data: { message: string } }>(`/api/email/change/verify`, {
      params,
    });
  }

  async resendChangeVerifyEmail() {
    return ApiService.http.get<void, { data: { message: string } }>(
      `/api/email/change/resend_verification`
    );
  }

  async verifyYandexToken(params: { email?: string; signature?: string; code?: string }) {
    return ApiService.http.post<void, { data: { message: string } }>(
      `/api/yandex/set_access_token`,
      params
    );
  }

  async verifyGoogleToken(params: { email?: string; signature?: string; code?: string }) {
    return ApiService.http.post<void, { data: { message: string } }>(
      `/api/google/set_access_token`,
      params
    );
  }

  async yandexAuth() {
    return ApiService.http.get<void, { data: { link: string } }>(`/api/yandex/auth`);
  }

  async googleAuth() {
    return ApiService.http.get<void, { data: { link: string } }>(`/api/google/auth`);
  }

  async googleOAuth(params: IGoogleAuthQuery) {
    return ApiService.http.get<void, { data: IAuthResponse }>('/api/auth/callback-google', {
      params,
    });
  }

  async revokeGoogleProperty() {
    return ApiService.http.get<void, { data: { google_analytics_refresh_token_status: string } }>(
      '/api/google/revoke_access'
    );
  }
}

export default new UserService();
