import { ActionTree, GetterTree, MutationTree } from 'vuex';

import { AdFormatState, IAdFormat, RootState } from '@/interfaces';
import CommonService from '@/services/CommonService';
import { FETCH_AD_FORMATS } from '@/store/types/actions';
import { SET_AD_FORMATS } from '@/store/types/mutations';
import { AD_FORMATS } from '@/utils/constants';

import { can } from '../helpers';

const state: AdFormatState = {
  adFormats: {
    data: [],
    loading: false,
    error: null,
  },
};

const getters: GetterTree<AdFormatState, RootState> = {
  adTypes: state => ({
    ...state.adFormats,
    data: state.adFormats.data.filter(adFormat => !adFormat.isSegment),
  }),
  adFormats: state => (withSegments: boolean = false) => ({
    ...state.adFormats,
    data: state.adFormats.data.filter(adFormat => withSegments || !adFormat.isSegment),
  }),
};

const mutations: MutationTree<AdFormatState> = {
  [SET_AD_FORMATS]: (state, payload: AdFormatState['adFormats']) => {
    state.adFormats = payload;
  },
};

const actions: ActionTree<AdFormatState, RootState> = {
  async [FETCH_AD_FORMATS]({ state, commit }) {
    try {
      commit(SET_AD_FORMATS, { ...state.adFormats, loading: true, error: null });
      const response = await CommonService.getAdFormats();
      const data = (Object.entries(AD_FORMATS) as [AdFormat, IAdFormat][])
        .sort(([, a], [, b]) => a.order - b.order)
        .filter(([value, { isSegment }]) => isSegment || can(value, 'see_adformats'))
        .map(([value, { text, isSegment, statsKey }]) => ({
          value,
          text,
          restricted: !response.data.includes(value),
          isSegment,
          statsKey,
        }));
      commit(SET_AD_FORMATS, { ...state.adFormats, data, loading: false });
    } catch (error) {
      commit(SET_AD_FORMATS, { ...state.adFormats, loading: false, error: null });
    }
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
