import Vue from 'vue';
import VueAnalytics from 'vue-analytics';

import router from '@/router';
import store from '@/store';

if (store.getters.environment == 'production' && process.env.VUE_APP_UI_VERSION === '1') {
  Vue.use(VueAnalytics, {
    id: 'UA-136035425-1',
    router,
    debug: {
      sendHitTask: store.getters.environment === 'production',
    },
  });
}
