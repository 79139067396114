var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('Button',{staticClass:"tw-hidden xs:tw-block tw-fixed tw-left-1 tw-z-45 tw-pb-1 tw-text-black dark:tw-text-white",attrs:{"icon":"","size":"md"},on:{"click":function($event){return _vm.changeVariant(!_vm.variant)}}},[_c('svg',{attrs:{"width":"25","height":"25","viewBox":"0 0 24 24"}},[_c('path',{attrs:{"d":"M3,6H21V8H3V6M3,11H21V13H3V11M3,16H21V18H3V16Z"}})])]),_c('div',{staticClass:"tw-fixed tw-top-0 tw-left-0 sm:tw-z-40 tw-z-50 tw-h-screen tw-p-4 tw-overflow-x-hidden tw-overflow-y-auto tw-transition-all tw-duration-200 tw-scrollbar-hide tw-bg-white sm:tw-sticky dark:tw-border-mybid-gray-light",class:{
      'tw-shadow-lg': _vm.variant,
      'tw--left-full': !_vm.variant,
      'tw-w-max': _vm.menuOpened,
      'sm:tw-w-64': !_vm.menuOpened,
      'dark:tw-bg-gray-light': _vm.env.VUE_APP_ORG_NAME === 'Onclicka',
      'dark:tw-bg-mybid-black': _vm.env.VUE_APP_ORG_NAME !== 'Onclicka',
    }},[_c('SidebarHeader',{attrs:{"menuOpened":_vm.menuOpened},on:{"toggle-menu":_vm.toggleMenu}}),_c('div',{staticClass:"tw-grid tw-grid-cols-1 tw-gap-10"},[_c('nav',[_c('SidebarUser',{attrs:{"menu-opened":_vm.menuOpened,"permissions":_vm.permissions.sidebar}}),(_vm.permissions.sidebar.admin_panels)?_c('SidebarAdmin',{attrs:{"metaData":_vm.metaData,"menuOpened":_vm.menuOpened,"permissions":_vm.permissions.sidebar}}):_vm._e()],1),_c('SidebarActions',{attrs:{"user":_vm.user,"menuOpened":_vm.menuOpened,"permissions":_vm.permissions.notification,"isDarkTheme":_vm.isDarkTheme,"notifications":_vm.notifications,"isLoggedAsUser":_vm.isLoggedAsUser,"notificationsCount":_vm.notificationsCount,"unmarkedNotifications":_vm.unmarkedNotifications},on:{"logout":_vm.logout,"actions":function (ref) {
        var type = ref.type;
        var notification = ref.notification;

        return _vm.actions(type, notification);
},"toggle-theme":_vm.toggleTheme,"get-notifications":_vm.getNotifications,"mark-notifications":_vm.markNotifications,"reset-user":_vm.resetUser,"get-notification-body":_vm.getNotificationBody}}),(!_vm.menuOpened)?_c('Info',{staticClass:"tw-px-5",attrs:{"user-manager":_vm.userManager}}):_vm._e(),(!_vm.menuOpened && !['Push-ok', 'Onclicka'].includes(_vm.env.VUE_APP_ORG_NAME))?_c('router-link',{staticClass:"tw-px-5 tw-font-gotham tw-text-sm tw-font-light tw-underline tw-text-black dark:tw-text-white tw-transition hover:tw-bg-mybid-secondary",attrs:{"to":"/terms-and-conditions"}},[_vm._v("\n        Terms & Conditions\n      ")]):_vm._e()],1)],1),_c('Overlay',{attrs:{"value":_vm.variant},on:{"change":function($event){return _vm.changeVariant(!_vm.variant)}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }