import numeral from 'numeral';

function isObject(val: any) {
  return val.constructor === Object;
}

function isNumber(val: any) {
  return !isNaN(parseFloat(val)) && isFinite(val);
}

function isBoolean(val: any) {
  return val === 'false' || val === 'true';
}

function isArray(val: any) {
  return Array.isArray(val);
}

function parseValue(v: any): any {
  const val = decodeURIComponent(v);
  if (typeof val == 'undefined' || val == '') {
    return null;
  } else if (isBoolean(val)) {
    return parseBoolean(val);
  } else if (isArray(val)) {
    return parseArray(val);
  } else if (isObject(val)) {
    return parseObject(val);
  } else if (isNumber(val)) {
    return parseNumber(val);
  } else {
    return val;
  }
}

function parseObject(obj: any) {
  let result: any = {};
  let key, val;
  for (key in obj) {
    val = parseValue(obj[key]);
    if (val !== null) result[key] = val; // ignore null values
  }
  return result;
}

function parseArray(arr: any) {
  let result = [];
  for (let i = 0; i < arr.length; i++) {
    result[i] = parseValue(arr[i]);
  }
  return result;
}

function parseNumber(val: any) {
  return Number(val);
}

function parseBoolean(val: any) {
  return val === 'true';
}

const parseJWT = (token: string) => JSON.parse(atob(token.split('.')[1]));

const parseByNumeral = (num: number | string | numeral.Numeral) => numeral(num).value();

export { parseByNumeral, parseJWT, parseValue };
