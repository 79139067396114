import router from '@/router';
import { LoginService } from '@/services/StorageService';
import TokenService from '@/services/TokenService';
import store from '@/store';
import UserModel from '@/store/models/UserModel';
import { SET_IS_AUTHENTICATED, SET_USER } from '@/store/types/mutations';

export class UnauthorizedErrorHandler {
  canHandle(error: any) {
    return error?.response?.status === 401;
  }

  async handle() {
    if (!store.getters.isAuthenticated) return;
    LoginService.removeLoginAsUser();
    // TODO: Change to action
    store.commit(SET_USER, { ...store.getters.user, data: { ...UserModel } });
    store.commit(SET_IS_AUTHENTICATED, false);
    TokenService.disconnectSocket();
    if (router.currentRoute.path !== '/login') {
      await router.replace({
        name: 'Login',
        params: { prevRoute: router.currentRoute.fullPath },
      });
    }
  }
}
