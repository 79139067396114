import * as Sentry from '@sentry/browser';
import * as Integrations from '@sentry/integrations';
import Vue from 'vue';

import store from '@/store';

if (store.getters.environment === 'production' && process.env.VUE_APP_ORG_NAME === 'Clickadilla') {
  Sentry.init({
    dsn: 'https://2b9bc443e13e424596f0ef6783338248@sentry.infra.infrapu.sh/18',
    integrations: [new Integrations.Vue({ Vue, attachProps: true })],
    beforeSend(event) {
      const nonErrorExceptions = [
        'Non-Error promise rejection',
        'Non-Error exception',
        'Redirected when going from',
        'Avoided redundant navigation to current location',
        'NotAllowedError',
        'Navigation cancelled',
      ];
      const isMathchedEvent = event.exception?.values?.some(({ value }) =>
        nonErrorExceptions.some(exception => value?.includes(exception))
      );
      return isMathchedEvent ? null : event;
    },
  });
}
