





















































import { Component, Emit, Prop, Vue, Watch } from 'vue-property-decorator';

import Button from '@/components/v2/Button.vue';
import Card from '@/components/v2/Card.vue';
import ChevronDown from '@/components/v2/Icons/ChevronDown.vue';

@Component({
  name: 'ExpansionPanel',
  components: {
    Card,
    Button,
    ChevronDown,
  },
})
export default class ExpansionPanel extends Vue {
  @Emit()
  change(value: boolean) {
    return value;
  }

  @Emit()
  click() {
    return;
  }

  @Emit()
  toggleExpand(value: boolean) {
    this.isExpanded = value;
    this.change(value);
    return;
  }

  @Prop({ default: false, type: Boolean }) value!: boolean;
  @Prop({ default: false, type: Boolean }) outlined!: boolean;
  @Prop({ default: false, type: Boolean }) disabled!: boolean;
  @Prop({ default: false, type: Boolean }) dense!: boolean;
  @Prop({ default: true, type: Boolean }) withActions!: boolean;

  @Watch('value')
  onChangeValue(value: boolean) {
    if (this.disabled && value) return;
    this.isExpanded = value;
  }

  isExpanded = this.value;

  get env() {
    return process.env;
  }
}
