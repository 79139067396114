import Vue from 'vue';

import WelcomePopup from '@/components/v2/popups/Welcome.vue';

const Popups = (function Popups() {
  Vue.component('welcomePopup', WelcomePopup);
  Vue.prototype.$popups = {
    welcome: new Vue(),
  };
})();

export default Popups;
