import 'typeface-roboto/index.css';
import '@/plugins';
import '@/assets/style/app.scss';

import Vue from 'vue';

import App from '@/App.vue';
import i18n from '@/plugins/i18n';
import router from '@/router';
import store from '@/store';
import rules from '@/utils/validation';

import vuetify from './plugins/vuetify';

Vue.config.productionTip = false;
Vue.prototype.$v = rules;

Vue.mixin({
  data() {
    return {
      dataIsLoading: false,
    };
  },
});

new Vue({
  router,
  store,
  i18n,
  vuetify,
  render: h => h(App, { props: { permissions: { update: false } } }),
}).$mount('#app');

if (process.env.VUE_APP_MOCK_SERVICE_WORKER_ENABLED === 'true') {
  const { worker } = require('@/mocks/browser');
  worker.start({ onUnhandledRequest: 'bypass' });
}
