import Vue from 'vue';

import Notifications from '@/components/NotificationSnackbar.vue';

const Notify = (function Notify() {
  Vue.component('notifications', Notifications);
  const notify = new Vue();
  Vue.prototype.$notify = notify;
})();

export default Notify;
