import axios from 'axios';
import omit from 'lodash/omit';

import { Memoize } from '@/decorators/memoize';
import { errorHandler } from '@/errors';
import {
  Device,
  IDataTableQuery,
  IPreviewConfig,
  IPreviewCreative,
  IResponse,
  ISpotPayload,
  ISpotsListQuery,
  ITag,
  IUserExtended,
  Spot,
  SpotDefaults,
} from '@/interfaces';
import store from '@/store';
import { REFRESH } from '@/store/types/actions';

class SpotService {
  apiService = axios.create({ baseURL: process.env.VUE_APP_BASEURL });

  constructor() {
    this.apiService.defaults.headers.common.Accept = 'application/json';
    this.apiService.defaults.headers.common['Content-Type'] = 'application/json;charset=UTF-8';
    this.apiService.interceptors.request.use(
      request =>
        new Promise(async resolve => {
          const isTokenExpired = store.getters.accessToken.data.expiresAt < Date.now();
          if (
            !request.url?.includes('api/auth/refresh') &&
            isTokenExpired &&
            store.getters.isAuthenticated
          ) {
            await store.dispatch(REFRESH);
            request.headers.common.Authorization = `Bearer ${store.getters.accessToken.data.token}`;
          }
          resolve(request);
        })
    );
    this.apiService.interceptors.response.use(
      response => response.data,
      error => {
        errorHandler(error);
        return Promise.reject(error);
      }
    );
  }

  addHeader({ name, value }: { name: string; value: string }) {
    this.apiService.defaults.headers.common[name] = value;
  }

  removeHeader(name: string) {
    delete this.apiService.defaults.headers.common[name];
  }

  async getSpots<T extends AdFormat>(
    params: Partial<IDataTableQuery> & ISpotsListQuery,
    adFormat: T
  ) {
    return this.apiService.get<void, IResponse<Spot<T>>>(`/api/spots/${adFormat}`, {
      params,
    });
  }

  async addSpot<T extends AdFormat>(params: ISpotPayload<T>, adFormat: T) {
    return this.apiService.post<void, { data: Spot<T> }>(
      `/api/spots/${adFormat}`,
      omit(params, ['visible_for_publisher'])
    );
  }

  async updateSpot<T extends AdFormat>(id: number, params: ISpotPayload<T>, adFormat: T) {
    return this.apiService.put<void, { data: Spot<T> }>(
      `/api/spots/${adFormat}/${id}`,
      omit(params, ['visible_for_publisher'])
    );
  }

  async getSpot<T extends AdFormat>(id: number, adFormat: T) {
    return this.apiService.get<void, { data: Spot<T> }>(`/api/spots/${adFormat}/${id}`);
  }

  async getTagSpots<T extends AdFormat>(
    id: ITag['id'],
    adformat: T | null,
    params: Partial<IDataTableQuery> & Partial<Nullable<{ name: string; id: number }>>
  ) {
    return this.apiService.get<void, IResponse<Spot>>(`/api/tags/${id}/spots`, {
      params: {
        ...params,
        ...{ adformat },
      },
    });
  }

  async hideSpot<T extends AdFormat>(id: Spot['id'], adFormat: T) {
    return this.apiService.post<void, { data: Spot<T> }>(`/api/spots/${adFormat}/${id}/hide`);
  }

  async unhideSpot<T extends AdFormat>(id: Spot['id'], adFormat: T) {
    return this.apiService.post<void, { data: Spot<T> }>(`/api/spots/${adFormat}/${id}/unhide`);
  }

  async banSpot<T extends AdFormat>(id: Spot['id'], adformat: T) {
    return this.apiService.post<void, { data: Spot<T> }>(`api/admin/spots/${adformat}/${id}/ban`);
  }

  async unbanSpot<T extends AdFormat>(id: Spot['id'], adformat: T) {
    return this.apiService.post<void, { data: Spot<T> }>(`api/admin/spots/${adformat}/${id}/unban`);
  }

  async deleteSpot<T extends AdFormat>(id: Spot['id'], adFormat: T) {
    return this.apiService.delete<void, void>(`/api/spots/${adFormat}/${id}`);
  }

  @Memoize
  async getSpotDefaults<T extends AdFormat>(adformat: T, user_id?: IUserExtended['id']) {
    return this.apiService.get<void, { data: SpotDefaults<T> }>(`/api/spots/create`, {
      params: {
        adformat,
        user_id,
      },
    });
  }

  async downloadServiceWorker<T extends Extract<AdFormat, 'push'>>(id: Spot['id'], adFormat: T) {
    return this.apiService.get<void, string>(`/api/spots/${adFormat}/${id}/worker`);
  }

  async previewSpot<T extends Extract<AdFormat, 'native'>>(
    params: Partial<Pick<Spot<T>, 'name'>> & Pick<Spot<T>, 'options'> & { currentDevice: Device }
  ) {
    return this.apiService.post<
      void,
      { config: IPreviewConfig; creatives: IPreviewCreative[] } & Pick<
        Spot<T>,
        'visible_for_publisher'
      >
    >('/api/spots/preview', params);
  }

  async copySpot<T extends AdFormat>(
    id: Spot['id'],
    adFormat: T,
    params: { tag_id: ITag['id']; name: Spot['name'] }
  ) {
    return this.apiService.post<void, { data: Spot<T> }>(
      `/api/spots/${adFormat}/${id}/copy`,
      params
    );
  }

  async getRelevantAdFormats(params: { adformat: AdFormat | null; hidden: boolean | null }) {
    return this.apiService.get<void, { data: AdFormat[] }>(`/api/spots/adformats-that-exist-for`, {
      params,
    });
  }
}

export default new SpotService();
