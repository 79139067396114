const model = {
  acceptable_quality: false,
  id: null,
  created_at: null,
  deleted_at: null,
  permissions: {
    nested: {},
    resource_permissions: [],
  },
  tips: null,
  uuid: '',
  banned: false,
  balance: '',
  email: '',
  email_verified_at: '',
  first_name: '',
  last_name: '',
  has_tags: false,
  has_pending_email_change: false,
  referrer_url: null,
  roles: [],
  utm_campaign: null,
  utm_content: null,
  utm_medium: null,
  utm_source: null,
  utm_term: null,
  account_notes: [],
  manager_notes: [],
  registration_date: null,
  restriction_adformats: [],
  wrong_email_address: null,
  yandex_metrika_token_status: null,
  google_analytics_refresh_token_status: null,
  labels: [],
  manager: null,
  is_own: false,
  email_history: [],
  roles_can_be_assigned: [],
  comment: null,
  address: null,
  balance_updated_at: null,
  ban_reason: null,
  fingerprints_banned: false,
  city: null,
  company: null,
  country_code: null,
  payment_method_details: null,
  payment_method_name: null,
  referral_code: '',
  referrer_code: null,
  state: null,
  website_url: null,
  zip_code: null,
  contacts: [],
  test_group_id: null,
  test_group_name: null,
};

export default model;
