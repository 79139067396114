import last from 'lodash/last';

import {
  ILogout,
  IPaymentRequestStatus,
  IStatsStorage,
  IUserStorage,
  PaymentRequestsStorage,
  Popup,
  StorageKey,
} from '@/interfaces';
import store from '@/store';

export const DARK_THEME_KEY = 'dark_theme';
export const REFERRAL_KEY = 'referral';
export const MENU_KEY = 'menu';
export const STATISTICS_KEY = 'statistics';
export const USERS_KEY = 'users';
export const UTM_KEY = 'UTM';
export const LOGIN_KEY = 'login';
export const LOGOUT_KEY = 'logout';
export const LOGIN_AS_USER_KEY = 'login_as_user';
export const SPOTS_LIST_KEY = 'spots_list';
export const LOCALE_KEY = 'locale';
export const POPUP_KEY = 'popups';
export const PAYMENT_REQUESTS_KEY = 'payment_requests';
export const REFERRER_KEY = 'referrer';

// TODO: Get rid of this beautiful listener =_=
window.addEventListener('storage', e => {
  const { role } = LogoutService.getLogout();
  const isAdmin = ['admin', 'manager'].includes(role);
  const storeRole = last<string>(store.getters.roles);
  if (e.key === LOGOUT_KEY && (isAdmin || storeRole === role)) {
    window.location.reload();
  }
});

const Storage = {
  localStorage: window.localStorage,
  sessionStorage: window.sessionStorage,

  get<T>(key: StorageKey, sessionStorage = false): T | null {
    const storage = sessionStorage ? this.sessionStorage : this.localStorage;
    this.removeIfExpired(key, storage);
    const value: string | null = storage.getItem(key);
    if (value === null) return null;
    const result = JSON.parse(value);
    return result.value;
  },

  set<T>(key: StorageKey, value: T, ttl = Infinity, sessionStorage = false) {
    const storage = sessionStorage ? this.sessionStorage : this.localStorage;
    this.removeIfExpired(key, storage);
    const now = new Date();
    const _expiry = now.getTime() + ttl;
    const payload = {
      value,
      _expiry,
    };
    storage.setItem(key, JSON.stringify(payload));
  },

  remove(key: StorageKey, sessionStorage = false) {
    const storage = sessionStorage ? this.sessionStorage : this.localStorage;
    storage.removeItem(key);
  },

  removeIfExpired(key: StorageKey, storage: Storage) {
    const now = new Date();
    const str: string | null = storage.getItem(key);
    if (str === null) return;
    const value = JSON.parse(str);
    if (
      value.hasOwnProperty('_expiry') &&
      value._expiry !== null &&
      value._expiry < now.getTime()
    ) {
      storage.removeItem(key);
    }
  },
};

const ThemeService = {
  storage: Storage,
  getDarkTheme() {
    const isDarkTheme = this.storage.get<boolean>(DARK_THEME_KEY);
    if (isDarkTheme) {
      document.documentElement.classList.add('tw-dark');
    } else {
      document.documentElement.classList.remove('tw-dark');
    }
    return isDarkTheme;
  },
  setDarkTheme(isDarkTheme: boolean) {
    if (isDarkTheme) {
      document.documentElement.classList.add('tw-dark');
    } else {
      document.documentElement.classList.remove('tw-dark');
    }
    this.storage.set<boolean>(DARK_THEME_KEY, isDarkTheme);
  },
};

const ReferralService = {
  storage: Storage,
  getReferral() {
    return this.storage.get<string>(REFERRAL_KEY, true) ?? '';
  },
  setReferral(ref: string) {
    this.storage.set<string>(REFERRAL_KEY, ref, Infinity, true);
  },
  removeReferral() {
    this.storage.remove(REFERRAL_KEY, true);
  },
};

const LoginService = {
  storage: Storage,
  getLogin() {
    const roles = this.storage.get<string[] | string>(LOGIN_KEY);
    if (typeof roles === 'string') return [roles];
    return roles?.length ? roles : ['publisher'];
  },
  setLogin() {
    this.storage.set<string[]>(LOGIN_KEY, store.getters.roles ?? ['publisher']);
  },
  getLoginAsUser() {
    return this.storage.get<string>(LOGIN_AS_USER_KEY) ?? '';
  },
  setLoginAsUser(userID: string) {
    this.storage.set<string>(LOGIN_AS_USER_KEY, userID);
  },
  removeLoginAsUser() {
    this.storage.remove(LOGIN_AS_USER_KEY);
  },
};

const LogoutService = {
  storage: Storage,
  getLogout() {
    return this.storage.get<ILogout>(LOGOUT_KEY) ?? { role: 'publisher', time: Infinity };
  },
  setLogout() {
    const role = last<string>(store.getters.roles) ?? 'publisher';
    this.storage.set<ILogout>(LOGOUT_KEY, { role, time: Date.now() });
  },
};

const MenuService = {
  storage: Storage,
  getExpand() {
    return this.storage.get<boolean>(MENU_KEY) ?? false;
  },
  setExpand() {
    this.storage.set<boolean>(MENU_KEY, !this.getExpand());
  },
};

const StatisticsService = {
  storage: Storage,
  getTreeGrid() {
    return this.storage.get<IStatsStorage>(STATISTICS_KEY)?.treeGrid ?? true;
  },
  setTreeGrid(treeGrid: IStatsStorage['treeGrid']) {
    const payload = { treeGrid };
    this.storage.set(STATISTICS_KEY, payload);
  },
};

const UsersService = {
  storage: Storage,
  getHeaders() {
    return this.storage.get<IUserStorage>(USERS_KEY) ?? { headers: [], groups: [] };
  },
  setHeaders(headers: IUserStorage['headers'], groups: IUserStorage['groups']) {
    this.storage.set<IUserStorage>(USERS_KEY, { headers, groups });
  },
};

const UTMService = {
  storage: Storage,
  getUTM() {
    return this.storage.get<Dictionary<string | (string | null)[]>>(UTM_KEY) ?? {};
  },
  setUTM(UTM: Dictionary<string | (string | null)[]>) {
    const payload = {
      ...this.getUTM(),
      ...Object.keys(UTM)
        .filter(key => UTM[key])
        .reduce((acc, key) => ({ ...acc, [key]: UTM[key] }), {}),
    };
    this.storage.set<Dictionary<string | (string | null)[]>>(UTM_KEY, payload);
  },
  removeUTM() {
    this.storage.remove(UTM_KEY);
  },
};

const SpotsListService = {
  storage: Storage,
  getCardsView() {
    return this.storage.get<boolean>(SPOTS_LIST_KEY) ?? true;
  },
  setCardsView(cardsView: boolean) {
    this.storage.set<boolean>(SPOTS_LIST_KEY, cardsView);
  },
};

const LocaleService = {
  storage: Storage,
  getLocale() {
    return this.storage.get<Locale | null>(LOCALE_KEY) ?? null;
  },
  setLocale(locale: Locale) {
    this.storage.set<Locale>(LOCALE_KEY, locale);
  },
};

const PopupsService = {
  storage: Storage,
  getPopups() {
    return (
      this.storage.get<Record<Popup, boolean>>(POPUP_KEY) ?? {
        notifications: false,
        welcome: false,
      }
    );
  },
  setPopups(popups: Record<Popup, boolean>) {
    return this.storage.set(POPUP_KEY, popups);
  },
};

const PaymentRequestsService = {
  storage: Storage,
  getHeaders(status: IPaymentRequestStatus['value']) {
    const records = this.storage.get<PaymentRequestsStorage>(PAYMENT_REQUESTS_KEY);
    if (!records || !records[status]) return { headers: [], groups: [] };
    return records[status];
  },
  setHeaders(status: IPaymentRequestStatus['value'], headers: string[], groups: string[]) {
    const records = {
      ...this.storage.get<PaymentRequestsStorage>(PAYMENT_REQUESTS_KEY),
      [status]: { headers, groups },
    };
    this.storage.set<PaymentRequestsStorage>(
      PAYMENT_REQUESTS_KEY,
      records as PaymentRequestsStorage
    );
  },
};

const ReferrerService = {
  storage: Storage,
  getReferrer() {
    return this.storage.get<string>(REFERRER_KEY, true) ?? document.referrer;
  },
  setReferrer(url: string) {
    this.storage.set<string>(REFERRER_KEY, url, Infinity, true);
  },
  removeReferrer() {
    this.storage.remove(REFERRER_KEY, true);
  },
};

export {
  LocaleService,
  LoginService,
  LogoutService,
  MenuService,
  PaymentRequestsService,
  PopupsService,
  ReferralService,
  ReferrerService,
  SpotsListService,
  StatisticsService,
  ThemeService,
  UsersService,
  UTMService,
};
