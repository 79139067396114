




































import { Component, Emit, Prop, Vue } from 'vue-property-decorator';

@Component({
  name: 'Card',
})
export default class Card extends Vue {
  @Emit()
  click() {
    return;
  }

  @Prop({ default: null }) className!: string;
  @Prop({ default: false, type: Boolean }) outlined!: boolean;
  @Prop({ default: 'none' }) shadow!: 'none' | 'sm' | 'md' | 'lg' | 'xl' | '2xl';
  @Prop({ default: null }) width!: number | null;
  @Prop({}) background!: string;
  @Prop({
    default: ['Clickadilla', 'Onclicka'].includes(process.env.VUE_APP_ORG_NAME),
    type: Boolean,
  })
  rounded!: boolean;

  get env() {
    return process.env;
  }
}
