











import { Component, Vue } from 'vue-property-decorator';

@Component({
  name: 'ChevronDown',
})
export default class ChevronDown extends Vue {}
