import Vue from 'vue';
import VueI18n from 'vue-i18n';

import en from '@/assets/i18n/en';
import ru from '@/assets/i18n/ru';
import { LocaleService } from '@/services/StorageService';

Vue.use(VueI18n);

const [browserLocale] = navigator.language.split('-');

const setLocale = () => {
  if (process.env.VUE_APP_UI_VERSION === '2') {
    return 'en';
  }

  return LocaleService.getLocale() || (['en', 'ru'].includes(browserLocale) ? browserLocale : 'en');
};

const i18n = new VueI18n({
  locale: setLocale(),
  messages: { en, ru },
});

export default i18n;
