var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"tw-grid tw-grid-cols-1"},[(_vm.permissions.viewAny)?_c('SidebarNotifications',{attrs:{"menu-opened":_vm.menuOpened,"permissions":_vm.permissions,"notifications":_vm.notifications,"notifications-count":_vm.notificationsCount,"unmarked-notifications":_vm.unmarkedNotifications},on:{"actions":function (ref) {
    var type = ref.type;
    var notification = ref.notification;

    return _vm.actions(type, notification);
},"get-notifications":_vm.getNotifications,"mark-notifications":_vm.markNotifications,"get-notification-body":_vm.getNotificationBody}}):_vm._e(),_vm._l((_vm.items.filter(function (ref) {
    var visible = ref.visible;

    return visible;
})),function(item){return _c('button',{key:item.name,staticClass:"tw-flex tw-items-center tw-gap-2.5 tw-w-full tw-py-4 tw-px-5 tw-transition tw-text-black hover:tw-bg-gray-light",class:{
      'tw-justify-center': _vm.menuOpened,
      'dark:tw-text-black dark:tw-bg-gray-light dark:hover:tw-text-mybid-primary':
        _vm.env.VUE_APP_ORG_NAME === 'Onclicka',
      'dark:tw-text-white dark:tw-bg-mybid-black dark:hover:tw-bg-mybid-gray':
        _vm.env.VUE_APP_ORG_NAME !== 'Onclicka',
    },on:{"click":function($event){return _vm.clickHandler(item.handler)}}},[_c('span',{staticClass:"tw-fill-current",domProps:{"innerHTML":_vm._s(item.icon)}}),(!_vm.menuOpened)?_c('span',{staticClass:"tw-flex tw-flex-col tw-items-start tw-font-gotham tw-text-sm",domProps:{"innerHTML":_vm._s(item.text)}}):_vm._e()])})],2)}
var staticRenderFns = []

export { render, staticRenderFns }