/* eslint-disable */
// @ts-nocheck

function initDashly() {
  (function() {
    function t(t, e) {
      return function() {
        window.dashlyasync.push(t, arguments);
      };
    }
    if ('undefined' == typeof dashly) {
      let e = document.createElement('script');
      (e.type = 'text/javascript'),
        (e.async = !0),
        (e.src = 'https://cdn.dashly.app/api.min.js'),
        document.getElementsByTagName('head')[0].appendChild(e),
        (window.dashly = {}),
        (window.dashlyasync = []),
        (dashly.settings = {});
      for (
        let n = [
            'connect',
            'track',
            'identify',
            'auth',
            'onReady',
            'addCallback',
            'removeCallback',
            'trackMessageInteraction',
          ],
          a = 0;
        a < n.length;
        a++
      )
        dashly[n[a]] = t(n[a]);
    }
  })();
}

export default initDashly;
export const hideDashly = () => document.getElementById('carrotquest-messenger-collapsed-container')?.style.display = 'none';
export const unhideDashly = () => document.getElementById('carrotquest-messenger-collapsed-container')?.style.display = 'block';
