import Vue from 'vue';
import Vuex, { StoreOptions } from 'vuex';

import { RootState } from '@/interfaces';
import accrualRules from '@/store/modules/accrualRules';
import adFormats from '@/store/modules/adFormats';
import application from '@/store/modules/application';
import auth from '@/store/modules/auth';
import payments from '@/store/modules/payments';
import user from '@/store/modules/user';

Vue.use(Vuex);

const store: StoreOptions<RootState> = {
  modules: {
    accrualRules,
    adFormats,
    application,
    auth,
    payments,
    user,
  },
};

export default new Vuex.Store<RootState>(store);
