var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"tw-flex tw-items-center",class:{
    'tw-justify-center': _vm.env.VUE_APP_ORG_NAME === 'Onclicka',
    'tw-justify-between': _vm.env.VUE_APP_ORG_NAME !== 'Onclicka',
  }},[_c('router-link',{staticClass:"tw-flex tw-items-center tw-gap-2.5 tw-py-4 tw-px-2.5",attrs:{"to":['Clickadilla', 'MyBid', 'Push-ok'].includes(_vm.env.VUE_APP_ORG_NAME) ? '/' : '/ad-codes'}},[(_vm.env.VUE_APP_ORG_NAME === 'Clickadilla')?[_c('Logo',{attrs:{"color":_vm.isDarkTheme ? 'gray-light' : 'black'}}),(!_vm.menuOpened)?_c('div',{staticClass:"tw-flex tw-flex-col tw-font-gotham tw-font-bold tw-leading-tight tw-select-none tw-text-mybid-secondary"},[_c('span',{staticClass:"tw-text-sm tw-uppercase"},[_vm._v("Publisher's")]),_c('span',{staticClass:"tw-text-xxs"},[_vm._v("Dashboard")])]):_vm._e()]:_vm._e(),(_vm.env.VUE_APP_ORG_NAME === 'MyBid')?[(!_vm.menuOpened)?_c('img',{attrs:{"alt":"Logo","src":require('@/assets/images/mybid-logo.svg')}}):_c('img',{attrs:{"alt":"Logo","src":require('@/assets/images/mybid-logo-minimal.svg')}})]:_vm._e(),(_vm.env.VUE_APP_ORG_NAME === 'Push-ok')?[_c('div',{staticClass:"tw-text-2xl tw-uppercase tw-font-bold tw-text-mybid-secondary"},[(!_vm.menuOpened)?[_vm._v("\n          Push-ok\n        ")]:[_vm._v("P")]],2)]:_vm._e(),(_vm.env.VUE_APP_ORG_NAME === 'Onclicka')?[_c('img',{attrs:{"alt":"Onclicka","src":require('@/assets/images/onclicka-logo.svg')}})]:_vm._e()],2),(_vm.showMenu)?_c('Button',{class:[].concat( (_vm.menuOpened
        ? ['tw-text-mybid-secondary']
        : ['tw-text-black dark:tw-text-white hover:tw-text-mybid-secondary']) ),attrs:{"icon":""},on:{"click":_vm.toggleMenu}},[_c('svg',{attrs:{"width":"14","height":"14","viewBox":"0 0 24 24"}},[_c('path',{attrs:{"d":_vm.menuOpened
            ? 'M19,13H3V11H19L15,7L16.4,5.6L22.8,12L16.4,18.4L15,17L19,13M3,6H13V8H3V6M13,16V18H3V16H13Z'
            : 'M5,13L9,17L7.6,18.42L1.18,12L7.6,5.58L9,7L5,11H21V13H5M21,6V8H11V6H21M21,16V18H11V16H21Z'}})])]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }