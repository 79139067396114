import { ActionTree, GetterTree, MutationTree } from 'vuex';

import { AccrualRuleState, RootState } from '@/interfaces';
import AdminService from '@/services/AdminService';
import { FETCH_ACCRUAL_RULE_TYPES } from '@/store/types/actions';
import { SET_ACCRUAL_RULE_TYPES } from '@/store/types/mutations';

const state: AccrualRuleState = {
  types: {
    data: [],
    loading: false,
    error: null,
  },
};

const getters: GetterTree<AccrualRuleState, RootState> = {
  accrualRuleTypes: state => state.types,
};

const mutations: MutationTree<AccrualRuleState> = {
  [SET_ACCRUAL_RULE_TYPES](state, payload: AccrualRuleState['types']) {
    state.types = payload;
  },
};

const actions: ActionTree<AccrualRuleState, RootState> = {
  async [FETCH_ACCRUAL_RULE_TYPES]({ commit, state }) {
    try {
      commit(SET_ACCRUAL_RULE_TYPES, { ...state.types, loading: true, error: null });
      const response = await AdminService.getAccrualRuleTypes();
      const data = Object.entries(response.data).map(([id, name]) => ({
        id,
        name,
      }));
      commit(SET_ACCRUAL_RULE_TYPES, { ...state.types, data, loading: false });
    } catch (error) {
      commit(SET_ACCRUAL_RULE_TYPES, { ...state.types, loading: false, error });
    }
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
