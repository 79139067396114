var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.isAlternative)?_c('div',{staticClass:"tw-grid tw-gap-3"},[_c('h3',{staticClass:"tw-flex tw-font-gotham tw-text-sm tw-text-black dark:tw-text-white",class:{
      'tw-flex-row tw-gap-1': !_vm.manager.first_name && !_vm.manager.last_name,
      'tw-flex-col': _vm.manager.first_name && _vm.manager.last_name,
    }},[_c('span',{class:{ 'tw-span-label': _vm.manager.first_name && _vm.manager.last_name }},[_vm._v("\n      "+_vm._s(_vm.manager.first_name && _vm.manager.last_name
          ? 'For extra change contact to your manager:'
          : 'Need help?')+"\n    ")]),_c('span',[_vm._v("\n      "+_vm._s(_vm.manager.first_name && _vm.manager.last_name
          ? ((_vm.manager.first_name) + " " + (_vm.manager.last_name))
          : 'Please contact us:')+"\n    ")])]),_c('div',{staticClass:"tw-flex tw-gap-2.5 tw-justify-between"},_vm._l((_vm.manager.contacts.concat( [{ name: 'email', value: _vm.manager.email }])),function(contact){return _c('Button',{key:contact.name,attrs:{"text":"","size":"md","color":"primary-light","href":_vm.generateContact(contact).href},scopedSlots:_vm._u([{key:"append-icon",fn:function(){return [_c(_vm.icons[contact.name],{tag:"component",staticClass:"tw-inline-block tw-size-4 tw-fill-current tw-text-green"})]},proxy:true}],null,true)},[_c('span',{staticClass:"tw-text-base tw-font-bold tw-leading-normal"},[_vm._v("\n        "+_vm._s(_vm.generateContact(contact).text)+"\n      ")])])}),1)]):_c('div',{staticClass:"tw-grid tw-gap-8"},[_c('h3',{staticClass:"tw-flex tw-flex-col tw-font-gotham tw-text-sm tw-font-bold tw-text-black dark:tw-text-white"},[_c('span',[_vm._v("\n      "+_vm._s(_vm.manager.first_name && _vm.manager.last_name ? 'Your account manager' : 'Need help?')+"\n    ")]),_c('span',[_vm._v("\n      "+_vm._s(_vm.manager.first_name && _vm.manager.last_name
          ? ((_vm.manager.first_name) + " " + (_vm.manager.last_name))
          : 'Please contact us:')+"\n    ")])]),_c('div',{staticClass:"tw-grid tw-grid-cols-1 tw-gap-2.5"},_vm._l((_vm.manager.contacts.concat( [{ name: 'email', value: _vm.manager.email }])),function(contact){return _c('a',{key:contact.name,staticClass:"tw-flex tw-items-center tw-gap-2.5 tw-font-gotham tw-text-xs tw-transition tw-text-black dark:tw-text-white hover:tw-text-mybid-secondary dark:hover:tw-text-mybid-secondary",attrs:{"href":_vm.generateContact(contact).href}},[_c(_vm.icons[contact.name],{tag:"component",staticClass:"tw-fill-current "}),_c('span',{staticClass:"tw-overflow-ellipsis tw-overflow-hidden"},[_vm._v("\n        "+_vm._s(_vm.generateContact(contact).text)+"\n      ")])],1)}),0)])}
var staticRenderFns = []

export { render, staticRenderFns }