








import { Component, Vue } from 'vue-property-decorator';

@Component({
  name: 'EmailIcon',
})
export default class EmailIcon extends Vue {}
