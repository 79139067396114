



























import { Component, Prop, Vue } from 'vue-property-decorator';

import { ISidebarPermissions } from '@/interfaces';

@Component({
  name: 'SidebarUser',
})
export default class SidebarUser extends Vue {
  @Prop({ required: true }) menuOpened!: boolean;
  @Prop({ required: true }) permissions!: ISidebarPermissions;

  get pages() {
    return [
      {
        name: 'Profile',
        icon: `
          <svg width="14" height="14" viewBox="0 0 14 14">
            <path
              d="M7 7.4375C6.48082 7.4375 5.97331 7.28355 5.54163 6.99511C5.10995 6.70667 4.7735 6.2967 4.57482 5.81705C4.37614 5.33739 4.32415 4.80959 4.42544 4.30039C4.52673 3.79119 4.77673 3.32346 5.14385 2.95635C5.51096 2.58923 5.97869 2.33923 6.48789 2.23794C6.99709 2.13665 7.52489 2.18864 8.00454 2.38732C8.4842 2.586 8.89417 2.92245 9.18261 3.35413C9.47105 3.78581 9.625 4.29333 9.625 4.8125C9.625 5.50869 9.34844 6.17637 8.85616 6.66866C8.36387 7.16094 7.69619 7.4375 7 7.4375ZM7 3.0625C6.65388 3.0625 6.31554 3.16514 6.02775 3.35743C5.73997 3.54972 5.51567 3.82303 5.38321 4.14281C5.25076 4.46258 5.2161 4.81444 5.28363 5.15391C5.35115 5.49338 5.51782 5.8052 5.76256 6.04994C6.00731 6.29468 6.31913 6.46135 6.65859 6.52888C6.99806 6.5964 7.34993 6.56174 7.6697 6.42929C7.98947 6.29684 8.26278 6.07254 8.45507 5.78475C8.64737 5.49696 8.75 5.15862 8.75 4.8125C8.75 4.34837 8.56563 3.90325 8.23744 3.57506C7.90925 3.24688 7.46413 3.0625 7 3.0625Z"
            />
            <path
              d="M6.99997 13.5625C6.02979 13.5619 5.07182 13.3461 4.19503 12.9308C3.31824 12.5155 2.54444 11.9109 1.92934 11.1606L1.70184 10.8806L1.92934 10.605C2.54496 9.85571 3.31898 9.25216 4.19574 8.83778C5.07251 8.42339 6.03021 8.20847 6.99997 8.20847C7.96973 8.20847 8.92743 8.42339 9.80419 8.83778C10.681 9.25216 11.455 9.85571 12.0706 10.605L12.2981 10.8806L12.0706 11.1606C11.4555 11.9109 10.6817 12.5155 9.80491 12.9308C8.92812 13.3461 7.97015 13.5619 6.99997 13.5625ZM2.84809 10.885C3.3802 11.4546 4.02371 11.9088 4.73869 12.2193C5.45367 12.5299 6.22485 12.6901 7.00434 12.6901C7.78384 12.6901 8.55502 12.5299 9.26999 12.2193C9.98497 11.9088 10.6285 11.4546 11.1606 10.885C10.6285 10.3154 9.98497 9.86121 9.26999 9.55069C8.55502 9.24017 7.78384 9.07994 7.00434 9.07994C6.22485 9.07994 5.45367 9.24017 4.73869 9.55069C4.02371 9.86121 3.3802 10.3154 2.84809 10.885Z"
            />
            <path
              d="M6.99999 13.5625C5.50931 13.5635 4.06272 13.057 2.89829 12.1262C1.73386 11.1955 0.920991 9.89612 0.59347 8.44187C0.265949 6.98762 0.443293 5.4652 1.09631 4.12517C1.74933 2.78514 2.83912 1.70736 4.1863 1.06923C5.53348 0.431089 7.05777 0.270619 8.5083 0.614227C9.95884 0.957836 11.2492 1.78505 12.1669 2.95971C13.0847 4.13438 13.5752 5.5865 13.5577 7.07707C13.5402 8.56764 13.0157 10.0078 12.0706 11.1606C11.4555 11.9109 10.6817 12.5155 9.80493 12.9308C8.92814 13.3461 7.97017 13.5619 6.99999 13.5625ZM6.99999 1.3125C5.87511 1.3125 4.77549 1.64607 3.84018 2.27102C2.90488 2.89597 2.1759 3.78424 1.74542 4.82349C1.31495 5.86275 1.20232 7.00631 1.42177 8.10958C1.64122 9.21285 2.18291 10.2263 2.97832 11.0217C3.77373 11.8171 4.78714 12.3588 5.89041 12.5782C6.99368 12.7977 8.13724 12.685 9.1765 12.2546C10.2158 11.8241 11.104 11.0951 11.729 10.1598C12.3539 9.2245 12.6875 8.12489 12.6875 7C12.6875 5.49159 12.0883 4.04495 11.0217 2.97833C9.95504 1.91172 8.50841 1.3125 6.99999 1.3125Z"
            />
            <path
              d="M2.26624 10.885C2.26624 10.885 6.67186 15.8069 11.1562 11.375L11.7337 10.885C11.7337 10.885 7.98874 7 4.18686 9.33187L2.26624 10.885Z"
            />
            <path
              d="M7 7C8.20812 7 9.1875 6.02062 9.1875 4.8125C9.1875 3.60438 8.20812 2.625 7 2.625C5.79188 2.625 4.8125 3.60438 4.8125 4.8125C4.8125 6.02062 5.79188 7 7 7Z"
            />
          </svg>
        `,
        path: '/profile',
        visible: true,
      },
      {
        name: 'Statistics',
        icon: `
          <svg width="14" height="14" viewBox="0 0 13 14">
            <path
              d="M6.23438 14H5.14062C4.59782 14 4.15625 13.5584 4.15625 13.0156V6.23438C4.15625 5.69157 4.59782 5.25 5.14062 5.25H6.23438C6.77718 5.25 7.21875 5.69157 7.21875 6.23438V13.0156C7.21875 13.5584 6.77715 14 6.23438 14Z"
            />
            <path
              d="M2.07812 14H0.984375C0.441574 14 0 13.5584 0 13.0156V8.42188C0 7.87907 0.441574 7.4375 0.984375 7.4375H2.07812C2.62093 7.4375 3.0625 7.87907 3.0625 8.42188V13.0156C3.0625 13.5584 2.6209 14 2.07812 14Z"
            />
            <path
              d="M10.3906 14H9.29688C8.75408 14 8.3125 13.5584 8.3125 13.0156V3.9375H7.71149C7.4305 3.9375 7.18299 3.77891 7.06557 3.52362C6.94816 3.26834 6.98882 2.97724 7.1717 2.76388C7.17377 2.76145 7.17591 2.75901 7.17804 2.75663L9.51793 0.145523C9.60091 0.0529102 9.71939 0 9.84375 0C9.96811 0 10.0866 0.0529102 10.1696 0.145523L12.5095 2.75663C12.5116 2.75901 12.5137 2.76145 12.5158 2.76388C12.6987 2.97721 12.7393 3.26834 12.6219 3.52362C12.5045 3.77891 12.257 3.9375 11.976 3.9375H11.375V13.0156C11.375 13.5584 10.9334 14 10.3906 14Z"
            />
          </svg>
        `,
        path: '/statistics',
        visible: true,
      },
      {
        name: 'AD Codes',
        icon: `
          <svg width="14" height="14" viewBox="0 0 14 14">
            <path
              d="M10.9201 0.745087H6.62162C6.34957 0.745087 6.08829 0.853396 5.8958 1.04589L0.300674 6.63999C-0.100225 7.04088 -0.100225 7.69074 0.300674 8.09215L4.59917 12.3906C5.00006 12.7915 5.64992 12.7915 6.05082 12.3906L11.6459 6.79655C11.8384 6.60354 11.9467 6.34226 11.9467 6.06969V1.77171C11.9467 1.2045 11.4873 0.745087 10.9201 0.745087ZM9.12352 4.33829C8.69849 4.33829 8.35355 3.99334 8.35355 3.56831C8.35355 3.14329 8.69849 2.79834 9.12352 2.79834C9.54854 2.79834 9.89349 3.14329 9.89349 3.56831C9.89349 3.99334 9.54854 4.33829 9.12352 4.33829Z"
            />
            <path
              d="M12.9733 1.77173L12.9728 6.55017C12.9728 6.78681 12.8789 7.01421 12.711 7.18155L7.02557 12.867L7.11284 12.9543C7.51373 13.3552 8.16359 13.3552 8.56449 12.9543L13.6987 7.82113C13.8917 7.62864 14 7.36737 14 7.09531V2.79836C14 2.23114 13.5406 1.77173 12.9733 1.77173Z"
            />
          </svg>
        `,
        path: '/ad-codes',
        visible: true,
      },
      {
        name: 'Mediation',
        icon: `
          <svg width="14" height="14" viewBox="0 0 14 14">
            <path
              d="M5.18522 3.5261C3.5356 5.23287 3.89557 8.06531 5.78519 9.32005C5.84745 9.36141 5.93029 9.3532 5.98377 9.30095C6.38158 8.91225 6.71811 8.53575 7.01279 8.05691C7.05787 7.98365 7.02983 7.88859 6.95419 7.84761C6.66597 7.69146 6.37917 7.39867 6.21771 7.08939L6.21752 7.0895C6.02412 6.70438 5.95827 6.27267 6.06066 5.82682C6.06077 5.82685 6.06088 5.82688 6.06099 5.82688C6.17881 5.25607 6.79162 4.72509 7.25965 4.23402C7.25866 4.23369 7.25771 4.23334 7.25672 4.23301L9.01032 2.44322C9.70916 1.72996 10.8587 1.72408 11.5647 2.43017C12.278 3.12898 12.2898 4.28428 11.591 4.9975L10.5288 6.08977C10.4796 6.14032 10.4637 6.21408 10.4867 6.28074C10.7312 6.98995 10.7914 7.98994 10.6275 8.74546C10.6229 8.76661 10.649 8.78045 10.6641 8.76499L12.9248 6.45768C14.3689 4.98374 14.3567 2.58396 12.8976 1.12485C11.4085 -0.364218 8.98433 -0.351826 7.51056 1.15234L5.19427 3.51642C5.19121 3.51965 5.18831 3.52293 5.18522 3.5261Z"
            />
            <path
              d="M9.41149 9.64506C9.41146 9.64515 9.41141 9.64523 9.41138 9.64531C9.41283 9.64471 9.41417 9.64413 9.41562 9.64351C9.87698 8.79987 9.9678 7.83232 9.75158 6.88911L9.75059 6.89012L9.74953 6.88966C9.54422 6.04958 8.98088 5.21539 8.21579 4.70178C8.14997 4.6576 8.04484 4.66272 7.9832 4.71256C7.59565 5.02592 7.21631 5.42776 6.966 5.94054C6.92669 6.02105 6.95612 6.11776 7.0336 6.16273C7.32409 6.33138 7.58643 6.57829 7.76209 6.9061L7.76236 6.90591C7.89925 7.13748 8.03414 7.57687 7.94677 8.04898C7.94671 8.04898 7.94663 8.04898 7.94658 8.04898C7.86505 8.67491 7.23297 9.24906 6.73049 9.76642L6.73074 9.76666C6.34825 10.1578 5.37593 11.149 4.9866 11.5468C4.28779 12.26 3.1325 12.2718 2.41927 11.573C1.70605 10.8742 1.69425 9.71887 2.39306 9.00565L3.45839 7.91009C3.50667 7.86044 3.52308 7.78828 3.50161 7.72243C3.26506 6.99665 3.20026 6.01919 3.34957 5.26452C3.35372 5.24349 3.32785 5.23005 3.31283 5.24537L1.08619 7.51797C-0.372763 9.00704 -0.360399 11.4314 1.11371 12.9055C2.60269 14.3644 5.01459 14.3398 6.47348 12.8508C6.98031 12.2839 9.14986 10.2751 9.41149 9.64506Z"
            />
          </svg>
        `,
        path: '/mediation',
        visible: this.permissions.user_mediations,
      },
      {
        name: 'Direct offer',
        icon: `
          <svg width="14" height="14" viewBox="0 0 15 15">
            <path
              d="M14.7566 5.5616L9.60021 1.10891C9.14886 0.719118 8.4375 1.03555 8.4375 1.64106V3.98636C3.73157 4.04024 0 4.9834 0 9.44314C0 11.2432 1.1596 13.0264 2.4414 13.9587C2.84139 14.2497 3.41145 13.8845 3.26396 13.4129C1.93553 9.16447 3.89405 8.0366 8.4375 7.97124V10.5469C8.4375 11.1533 9.14941 11.4683 9.60021 11.079L14.7566 6.6259C15.0809 6.34576 15.0814 5.84212 14.7566 5.5616Z"
            />
          </svg>
        `,
        path: '/direct-offer',
        visible: this.permissions.own_offers,
      },
      {
        name: 'Landings',
        icon: `
          <svg width="14" height="14" viewBox="0 0 14 14">
            <path
              d="M6.99997 0C3.10938 0 0 3.15436 0 6.99997C0 10.8834 3.15442 14.0001 6.99836 14C10.8576 13.9999 13.9999 10.8737 13.9999 6.99997C13.9999 3.15885 10.8931 0 6.99997 0ZM5.44067 7.70782L4.925 9.94261C4.79577 10.5028 4.01711 10.5908 3.7755 10.0558L2.61536 7.48185L3.39004 5.20806C3.45659 5.0128 3.36775 4.79854 3.18258 4.70762L1.62744 3.94402C2.23168 2.8809 3.13008 2.02745 4.21776 1.48019V2.49894C4.21776 2.69267 4.35333 2.86001 4.54287 2.90018L6.64303 3.34536C6.9285 3.40573 7.15181 3.61874 7.2258 3.90114L7.42429 4.65889C7.48513 4.89161 7.43206 5.14279 7.28235 5.33078L5.51948 7.54452C5.48136 7.59237 5.45443 7.64818 5.44067 7.70782ZM6.54558 13.1633L6.78092 12.6665C6.8247 12.574 6.87332 12.5303 6.95942 12.4782C7.05028 12.4238 7.18815 12.3605 7.41483 12.2468C7.61758 12.1453 7.83518 12.2096 7.9538 12.3655C7.95383 12.3655 7.95388 12.3655 7.95391 12.3656L8.44451 13.0097C7.82539 13.1578 7.18301 13.2097 6.54558 13.1633ZM10.9827 11.7249L10.4452 11.3763L10.2042 9.14232C10.1804 8.92253 10.2375 8.70159 10.3647 8.52036L11.5752 6.79744C11.6237 6.7284 11.6498 6.64604 11.6498 6.56165V4.97914L12.4354 4.0572C12.9237 4.95677 13.1797 5.9637 13.1797 6.99997C13.1796 8.88622 12.3356 10.5818 10.9827 11.7249Z"
            />
          </svg>
        `,
        path: '/landings',
        visible: true,
      },
      {
        name: 'Payments',
        icon: `
          <svg width="14" height="14" viewBox="0 0 24 24">
            <path
              d="M20 4H4A2 2 0 0 0 2 6V18A2 2 0 0 0 4 20H20A2 2 0 0 0 22 18V6A2 2 0 0 0 20 4M20 11H4V8H20Z"
            />
          </svg>
        `,
        path: '/payments',
        visible: this.permissions.payment_requests,
      },
      {
        name: 'Referral',
        icon: `
          <svg width="14" height="14" viewBox="0 0 14 14">
            <path
              d="M12.3958 2.3333C12.3958 3.38037 11.5471 4.2292 10.5 4.2292C9.45291 4.2292 8.60419 3.38037 8.60419 2.3333C8.60419 1.28633 9.45291 0.4375 10.5 0.4375C11.5471 0.4375 12.3958 1.28633 12.3958 2.3333Z"
            />
            <path
              d="M10.5 4.6667C9.21312 4.6667 8.16669 3.62016 8.16669 2.3333C8.16669 1.04654 9.21312 0 10.5 0C11.7868 0 12.8333 1.04654 12.8333 2.3333C12.8333 3.62016 11.7868 4.6667 10.5 4.6667ZM10.5 0.875C9.69559 0.875 9.04169 1.52954 9.04169 2.3333C9.04169 3.13716 9.69559 3.7917 10.5 3.7917C11.3044 3.7917 11.9583 3.13716 11.9583 2.3333C11.9583 1.52954 11.3044 0.875 10.5 0.875Z"
            />
            <path
              d="M12.3958 11.6667C12.3958 12.7137 11.5471 13.5625 10.5 13.5625C9.45291 13.5625 8.60419 12.7137 8.60419 11.6667C8.60419 10.6196 9.45291 9.7708 10.5 9.7708C11.5471 9.7708 12.3958 10.6196 12.3958 11.6667Z"
            />
            <path
              d="M10.5 14C9.21312 14 8.16669 12.9535 8.16669 11.6667C8.16669 10.3798 9.21312 9.3333 10.5 9.3333C11.7868 9.3333 12.8333 10.3798 12.8333 11.6667C12.8333 12.9535 11.7868 14 10.5 14ZM10.5 10.2083C9.69559 10.2083 9.04169 10.8628 9.04169 11.6667C9.04169 12.4705 9.69559 13.125 10.5 13.125C11.3044 13.125 11.9583 12.4705 11.9583 11.6667C11.9583 10.8628 11.3044 10.2083 10.5 10.2083Z"
            />
            <path
              d="M4.2292 7C4.2292 8.04707 3.38037 8.8958 2.3333 8.8958C1.28633 8.8958 0.4375 8.04707 0.4375 7C0.4375 5.95293 1.28633 5.1042 2.3333 5.1042C3.38037 5.1042 4.2292 5.95293 4.2292 7Z"
            />
            <path
              d="M2.3333 9.3333C1.04654 9.3333 0 8.28687 0 7C0 5.71313 1.04654 4.6667 2.3333 4.6667C3.62016 4.6667 4.6667 5.71313 4.6667 7C4.6667 8.28687 3.62016 9.3333 2.3333 9.3333ZM2.3333 5.5417C1.5289 5.5417 0.875 6.19614 0.875 7C0.875 7.80386 1.5289 8.4583 2.3333 8.4583C3.1378 8.4583 3.7917 7.80386 3.7917 7C3.7917 6.19614 3.1378 5.5417 2.3333 5.5417Z"
            />
            <path
              d="M3.71062 6.72005C3.50758 6.72005 3.3104 6.61441 3.20306 6.42546C3.0438 6.14604 3.14185 5.78961 3.42127 5.62971L8.83395 2.54393C9.11336 2.3835 9.46979 2.48155 9.62969 2.76204C9.78895 3.04146 9.69089 3.39789 9.41148 3.55778L3.99869 6.64357C3.90769 6.69548 3.80857 6.72005 3.71062 6.72005Z"
            />
            <path
              d="M9.12276 11.5325C9.02471 11.5325 8.92559 11.508 8.83458 11.4561L3.4218 8.37028C3.14238 8.21103 3.04444 7.8546 3.20369 7.57454C3.36231 7.29458 3.71927 7.196 3.99933 7.35643L9.41211 10.4422C9.69153 10.6015 9.78948 10.9579 9.63022 11.238C9.52234 11.4269 9.32517 11.5325 9.12276 11.5325Z"
            />
          </svg>
        `,
        path: '/referral',
        visible: this.permissions.referral,
      },
      {
        name: 'API',
        icon: `
          <svg width="14" height="14" viewBox="0 0 14 14">
            <path
              d="M11.8926 6.20892C11.7088 5.03212 10.6886 4.12891 9.46094 4.12891C8.9991 4.12891 8.55531 4.25668 8.17039 4.49501C7.5848 3.51086 6.53056 2.89844 5.35938 2.89844C3.55012 2.89844 2.07812 4.37043 2.07812 6.17969C2.07812 6.1909 2.07812 6.20252 2.07854 6.21373C0.915742 6.40959 0 7.42336 0 8.64062C0 9.99767 1.13124 11.1016 2.48828 11.1016H11.5117C12.8688 11.1016 14 9.99767 14 8.64062C14 7.41297 13.0694 6.39278 11.8926 6.20892Z"
            />
          </svg>
        `,
        path: '/api',
        visible: true,
      },
      // {
      //   name: 'Help',
      //   icon: `
      //     <svg width="14" height="14" viewBox="0 0 14 14">
      //       <path
      //         d="M7 0C3.13111 0 0 3.13075 0 7C0 10.8688 3.13075 14 7 14C10.8689 14 14 10.8693 14 7C14 3.13111 10.8693 0 7 0ZM6.79287 10.2763C6.39666 10.2763 6.0926 9.94459 6.0926 9.56678C6.0926 9.17979 6.40588 8.85732 6.79287 8.85732C7.17989 8.85732 7.50233 9.17982 7.50233 9.56681C7.50233 9.94457 7.18905 10.2763 6.79287 10.2763ZM7.84325 6.692C7.33649 7.08821 7.32725 7.36463 7.32725 7.84375C7.32725 8.01886 7.2351 8.22155 6.78363 8.22155C6.40582 8.22155 6.27687 8.08333 6.27687 7.60421C6.27687 6.81179 6.627 6.43401 6.89421 6.20367C7.19827 5.94565 7.71427 5.66005 7.71427 5.1625C7.71427 4.73862 7.34571 4.53592 6.88499 4.53592C5.94516 4.53592 6.14789 5.24543 5.65029 5.24543C5.40151 5.24543 5.09745 5.07954 5.09745 4.72021C5.09745 4.22267 5.66871 3.48551 6.91264 3.48551C8.09203 3.48551 8.87523 4.13973 8.87523 5.00585C8.87523 5.87196 8.09203 6.49852 7.84325 6.692Z"
      //       />
      //     </svg>
      //   `,
      //   path: '/help',
      //   visible: true,
      // },
    ];
  }

  clickHandler() {
    if (process.env.VUE_APP_ORG_NAME === 'MyBid') {
      window.fbq('track', 'ViewContent');
    }

    window.dataLayer.push({
      event: 'clickmenu_pub',
    });
  }

  get env() {
    return process.env;
  }
}
