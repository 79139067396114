export default {
  meta: {
    description:
      'Платформа высокотехнологичной рекламы, созданная специально для владельцев сайтов.',
  },
  links: {
    faq: 'FAQ',
    login: 'Войти',
    signup: 'Зарегистрироваться',
    adformats: 'Форматы',
    advertisers: 'Рекламодателям',
    contacts: 'Контакты',
    terms: 'Условия пользования',
    policy: 'Политика конфиденциальности',
  },
  header: {
    title: 'Создан для паблишеров',
    subtitle: 'паблишерами',
    description:
      'Индивидуальное решение для владельцев сайтов. Полностью настраиваемые рекламные кампании. Один тег. Все форматы рекламы.',
    join: 'Регистрация',
  },
  preferences: {
    title: 'Наши преимущества',
    support: {
      title: 'Поддержка 24/7',
      text:
        'Никаких глупых тикетов и ожиданий — за ваш опыт на 100% отвечает ваш персональный менеджер',
    },
    mainstream_traffic: {
      title: 'Основной трафик',
      text:
        'Таргетированная реклама по самым популярным мейнстримным вертикалям: дейтинг, софт, гемблинг и так далее',
    },
    adult_traffic: {
      title: 'Трафик для взрослых',
      text:
        'Объявления высшего качества от премиальных и эксклюзивных рекламодателей с широким спектром категорий',
    },
    statistics: {
      title: 'Статистика в деталях',
      text:
        'Статистика в реальном времени с ежечасными обновлениями. Интегрированные GA и YM для лучшего управления трафиком',
    },
    tag: {
      title: 'Один тег',
      text:
        'Разместите наш тег на странице один раз и получите полное управление форматами объявлений в своей панели',
    },
  },
  adformats: {
    title: 'Форматы рекламы',
    popunder:
      'Дополнительное окно, которое появляется, когда пользователь щелкает в любом месте страницы или целевого элемента.',
    banner: 'Статический баннер 4 разных размеров для вашей контентной страницы.',
    push:
      'Соберите базу подписчиков на десктопе, Android и iOS, чтобы максимально монетизировать их.',
    in_stream_ad:
      'Разместите видеорекламу в плеере или разместите ролик или видео-слайдер на странице.',
    inpage_push:
      'Баннер, прикрепленный к определенной части веб-страницы или видеоплеера, может быть закрыт пользователем.',
    native:
      'Несколько блоков объявлений, соответствующих содержанию вашей страницы, остаются замаскированными.',
    button: {
      more: 'Больше форматов',
      less: 'Показать меньше',
      read: 'Узнать больше',
    },
  },
  payments: {
    title: 'Методы оплаты',
  },
  help: {
    info: {
      title: 'Нужна помощь?',
      text: [
        'Пожалуйста, посетите наш раздел ',
        'FAQ',
        ` чтобы открыть для себя функциональность ${process.env.VUE_APP_ORG_NAME}.`,
      ],
    },
    offer: {
      title: 'Есть предложение о партнерстве?',
      text: 'Пожалуйста, свяжитесь через форму, и мы свяжемся с вами как можно скорее.',
    },
    news: {
      title: 'Не пропустите новости и советы',
      text: [
        'Наш блог',
        ' поможет вам найти практические руководства, тематические исследования и советы.',
      ],
    },
    form: {
      name: 'Ваше имя',
      email: 'Email',
      topic: 'Тема',
      message: 'Ваше сообщение',
      submit: 'Отправить',
    },
  },
  dialog: {
    title: 'Спасибо!',
    text: 'Ваше сообщение было успешно отправлено.',
  },
  privacy: {
    title: 'Политика конфиденциальности',
    items: [
      {
        text: [
          'В соответствии с действующим законодательством о защите персональных данных вам сообщается о следующих аспектах.',
          `Вы можете связаться с ${process.env.VUE_APP_ORG_SHORT_URL} по следующему адресу электронной почты для любого запроса, запроса или разъяснения относительно обработки ваших личных данных: <a href="mailto:${process.env.VUE_APP_ORG_SUPPORT_EMAIL}?subject=Privacy">${process.env.VUE_APP_ORG_SUPPORT_EMAIL}</a>.`,
        ],
      },
      {
        title: 'Обработка и получение личной информации',
        text: [
          'Данные, которые мы будем обрабатывать для целей, указанных далее в этом документе, ограничены данными о деловых контактах, относящихся к компании, организации или организации, в которой вы работаете или с кем сотрудничаете.',
          'Данные, которые мы будем обрабатывать, были получены с помощью файлов архива информации, которую вы просматриваете, в соответствии с политикой использования файлов cookie каждого из наших пользователей (веб-сайты, на которых отображаются наши форматы рекламы). Полученные данные: URL-адрес веб-сайта, на котором отображаются наши объявления, реферальный URL-адрес, IP-адрес, страна, географический регион, браузер и операционная система.',
        ],
      },
      {
        title: 'Передача вашей личной информации в другие страны',
        text: [
          'Если мы передадим информацию в ЕС и другие регионы с полным законодательством о защите данных, мы сможем гарантировать, что все данные будут переданы в соответствии с настоящей Политикой конфиденциальности и разрешены законодательством о защите данных.',
          'Используя Веб-сайт, вы соглашаетесь с возможностью передачи информации о вас, которую мы собираем (включая личную информацию), в любую страну, в которой мы, члены нашей корпоративной группы или наш поставщик услуг расположены.',
        ],
      },
      {
        title: 'Сохранение личной информации',
        text: [
          '– Мы будем хранить ваши Личные данные в течение всего времени, необходимого для достижения целей, для которых мы их собираем, включая выполнение любых юридических, бухгалтерских или отчетных требований.',
          '– Чтобы выяснить подходящий срок хранения персональных данных, мы учитываем объем, характер и конфиденциальность ваших персональных данных, а также потенциальный риск причинения вреда в результате несанкционированного использования ваших персональных данных, целей, для которых мы обрабатываем ваши персональные данные, и мы можем достичь этих целей с помощью других средств и требований законодательства.',
          '– Если нам больше не нужно обрабатывать ваши Персональные данные в целях, указанных в настоящей Политике конфиденциальности, мы удалим ваши Персональные данные из нашей системы.',
          '– Мы также удалим ваши Личные данные по вашему запросу. Свяжитесь с нами через контактную форму, чтобы узнать, как это сделать.',
          `– Если у вас есть вопросы по поводу хранения данных, отправьте нам электронное письмо по адресу <a href="mailto:${process.env.VUE_APP_ORG_SUPPORT_EMAIL}?subject=Privacy">${process.env.VUE_APP_ORG_SUPPORT_EMAIL}</a>.`,
          '– Период, в течение которого мы храним ваши Личные данные, необходимые для соблюдения требований и правоприменения, варьируется и зависит от характера наших юридических обязательств в каждом конкретном случае.',
        ],
      },
      {
        title: 'Как мы защищаем вашу личную информацию',
        text: [
          'Мы принимаем меры безопасности (включая физические, электронные и процедурные меры), чтобы защитить ваши Личные данные от разглашения и несанкционированного доступа. Например, только авторизованным сотрудникам разрешено иметь доступ к Личной информации наших пользователей, и они могут делать это только для авторизованных бизнес-функций. В дополнение к этому мы используем шифрование при передаче вашей Личной информации между вашей системой и нашей, а также используем брандмауэры, чтобы предотвратить доступ посторонних лиц к вашей личной информации. Однако следует помнить, что мы не можем полностью устранить риски безопасности, связанные с хранением и передачей личных данных.',
          'Вы несете ответственность за сохранение секретности вашего уникального пароля и учетной записи в любое время. Мы не несем ответственности за исключение каких-либо мер конфиденциальности или безопасности, содержащихся на сайте.',
        ],
      },
      {
        title: 'Как мы собираем, используем и раскрываем вашу личную информацию',
        text: [
          'Мы пытаемся предоставить вам выбор относительно вашей личной информации.',
          'Вы можете не предоставлять нам свою Личную информацию, но это закроет доступ к некоторым функциям нашего Веб-сайта, поскольку такая информация понадобится при регистрации в качестве участника; участия в конкурсе; продвижения; опроса; при ответах на вопросы.',
          'Вы несете ответственность за сохранение секретности вашего уникального пароля и информации об учетной записи в любое время. Мы не несем ответственности за исключение каких-либо мер конфиденциальности или безопасности, содержащихся на веб-сайте.',
          'Вы можете настроить свой браузер так, чтобы он отказывался от файлов cookie браузера или предупреждал вас об отправке файлов cookie. Если вы отключите файлы cookie, обратите внимание, что некоторые части веб-сайта могут быть недоступны или работать неправильно.',
          'Если вы больше не хотите получать рекламные электронные письма и информационные рассылки, вам необходимо отписаться. Вы можете сделать это, связавшись с нашей службой поддержки через Live Chat.',
          'Если вы хотите удалить или деактивировать вашу учетную запись - вам необходимо связаться с нашей службой поддержки.',
          'Если вы удалите данные своей учетной записи, ваша личная информация и данные профиля пользователя больше не будут вам доступны. После удаления вашей учетной записи, если вы решите создать учетную запись у нас в будущем, вам придется зарегистрироваться еще раз, и ваша личная информация больше не будет доступна.',
        ],
      },
      {
        title: 'Ваши права, связанные с вашей личной информацией',
        text: [
          'В соответствии с местным законодательством у вас есть определенные права в отношении собираемой нами Личной информации, включая право',
          '– получать информацию о Личной информации, касающуюся вас, и того, как такая Личная информация используется (право доступа);',
          '– исправить неточную личную информацию о вас (право на исправление);',
          '– удалить вашу личную информацию («право быть забытым»);',
          '– получать Личную информацию, предоставленную вами, в структурированном формате и передавать эту Личную информацию другому контроллеру данных (право на переносимость данных);',
          '– возражать против использования вашей Личной информации, если такое использование основано на наших законных интересах или общественных интересах (право на возражение);',
          '– в исключительных случаях ограничивать использование нами вашей личной информации (право на ограничение обработки).',
        ],
      },
      {
        text: [
          'Если мы просим вашего разрешения на использование вашей личной информации, вы можете отклонить ее в любое время. Обратите внимание, что в случае отзыва вашего разрешения вы больше не сможете использовать некоторые функции нашего Веб-сайта и служб.',
          `Вы можете в любое время отправить нам электронное письмо по адресу <a href="mailto:${process.env.VUE_APP_ORG_SUPPORT_EMAIL}?subject=Privacy">${process.env.VUE_APP_ORG_SUPPORT_EMAIL}</a> и выяснить ваши вышеуказанные права в соответствии с применимыми правовыми требованиями и ограничениями. Если вы находитесь в ЕС, вы имеете право подать жалобу в местный орган по защите данных.`,
          'Обратите внимание, что некоторые запросы на удаление Личной информации потребуют удаления вашей личной учетной записи в качестве предоставления учетных записей пользователей (например, вашего адреса электронной почты). Также обратите внимание, что, возможно, нам потребуется дополнительная информация от вас, чтобы подтвердить ваше разрешение на выполнение запроса.',
        ],
      },
      {
        title: 'Сотрудничество',
        text: [
          `Мы регулярно проверяем соответствие нашей политике конфиденциальности. Пожалуйста, не стесняйтесь присылать любые вопросы относительно этой политики конфиденциальности, связавшись с нами через этот веб-сайт и по адресу <a href="mailto:${process.env.VUE_APP_ORG_SUPPORT_EMAIL}?subject=Privacy">${process.env.VUE_APP_ORG_SUPPORT_EMAIL}</a>. Когда мы получаем официальную письменную жалобу, наша политика заключается в том, чтобы связаться с стороной, подавшей жалобу. Мы будем сотрудничать с соответствующими регулирующими органами, включая местные органы защиты данных, для разрешения любых жалоб.`,
        ],
      },
      {
        title: 'Отсутствие прав третьих лиц',
        text: [
          'Данная политика конфиденциальности не создает прав, осуществляемых третьими лицами, и не требует раскрытия какой-либо Личной информации, относящейся к пользователям Веб-сайта.',
        ],
      },
      {
        title: 'Наша политика в отношении несовершеннолетних',
        text: [
          `Наш веб-сайт не предназначен для лиц моложе 18 лет, и мы сознательно не собираем личную информацию от несовершеннолетних. Если вам станет известно, что ваш ребенок предоставил нам Личную информацию, свяжитесь с нами по адресу <a href="mailto:${process.env.VUE_APP_ORG_SUPPORT_EMAIL}?subject=Privacy">${process.env.VUE_APP_ORG_SUPPORT_EMAIL}</a>. Если нам станет известно, что несовершеннолетний предоставил нам Личную информацию, мы удаляем такую информацию и закрываем учетную запись этого лица.`,
        ],
      },
      {
        title: 'Отсутствие гарантии на возможные ошибки',
        text: [
          'Мы не гарантируем безошибочную работу в соответствии с этой политикой конфиденциальности. Мы не несем ответственности за любые случайные, косвенные или штрафные убытки, связанные с данной политикой конфиденциальности.',
        ],
      },
      {
        title: 'Контактная информация',
        text: [
          `Если у вас есть какие-либо вопросы об этой политике конфиденциальности, пожалуйста, свяжитесь с нами по адресу <a href="mailto:${process.env.VUE_APP_ORG_SUPPORT_EMAIL}?subject=Privacy">${process.env.VUE_APP_ORG_SUPPORT_EMAIL}</a>.`,
        ],
      },
    ],
  },
};
