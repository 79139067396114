var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"tw-flex tw-flex-col tw-bg-gray-light border-b",class:{
    'tw-border tw-border-gray dark:tw-border-mybid-gray-light': _vm.outlined,
    'tw-border-none': !_vm.outlined,
    'dark:tw-bg-gray-light': _vm.env.VUE_APP_ORG_NAME === 'Onclicka',
    'dark:tw-bg-mybid-gray-dark': _vm.env.VUE_APP_ORG_NAME !== 'Onclicka',
    'tw-rounded-md': ['Onclicka', 'Clickadilla'].includes(_vm.env.VUE_APP_ORG_NAME) && !_vm.isExpanded,
    'tw-rounded-tl-md tw-rounded-tr-md':
      ['Onclicka', 'Clickadilla'].includes(_vm.env.VUE_APP_ORG_NAME) && _vm.isExpanded,
  }},[_c('div',{staticClass:"tw-flex tw-flex-nowrap tw-justify-between tw-items-center",class:{
      'tw-py-2 tw-px-5': _vm.dense,
      'tw-p-5': !_vm.dense,
    }},[_c('div',{staticClass:"tw-text-mybid-secondary tw-text-sm tw-font-medium tw-leading-7 tw-flex-grow"},[_vm._t("default",null,{"isExpanded":_vm.isExpanded})],2),(_vm.withActions)?_c('div',{staticClass:"tw-px-2.5"},[_vm._t("actions",[_c('Button',{class:['tw-transform tw-origin-center' ].concat( (_vm.isExpanded ? ['tw-rotate-180'] : [])),attrs:{"icon":"","color":"none","disabled":_vm.disabled},on:{"click":function($event){return _vm.toggleExpand(!_vm.isExpanded)}}},[_c('ChevronDown',{staticClass:"tw-fill-current tw-text-mybid-secondary tw-h-5 tw-w-5"})],1)],{"toggleExpand":_vm.toggleExpand,"isExpanded":_vm.isExpanded})],2):_vm._e()]),(_vm.isExpanded)?_c('div',[_c('div',{class:{
        'tw-w-full dark:tw-bg-for-image-invert tw-bg-gray-light-secondary':
          _vm.env.VUE_APP_ORG_NAME === 'Clickadilla',
      }},[_c('div',{class:_vm.dense ? 'tw-py-2 tw-px-5' : 'tw-p-5'},[_vm._t("content")],2)])]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }