export default {
  category_id: 0,
  category_name: '',
  category_group: '',
  created_at: '',
  custom_tag_labels: [],
  deleted_at: null,
  google_analytics_property: null,
  id: 0,
  name: '',
  options: {
    utm_mapping: {
      utm_source: null,
      utm_medium: null,
      utm_content: null,
      utm_campaign: null,
    },
    anti_adblock_urls: [],
  },
  test_id: null,
  test_status: null,
  script: '',
  updated_at: '',
  yandex_metrika_counter: null,
};
