import moment from 'moment';

const formatDate = (
  date: DateTimeString | moment.Moment,
  format: DateTimeFormat = 'YYYY/MM/DD',
  keepLocalTime = false
) => {
  if (!moment(date).isValid()) return null;
  return moment(date)
    .utc(keepLocalTime)
    .format(format);
};

const getDateDiff = (to: DateTimeString, from: DateTimeString) => {
  return moment(to).diff(from, 'days') + 1;
};

export { formatDate, getDateDiff };
